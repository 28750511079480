	/*
  	Flaticon icon font: Flaticon
  	Creation date: 27/07/2019 08:27
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../../fonts/landing/Flaticon.eot");
  src: url("../../fonts/landing/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../../fonts/landing/Flaticon.woff2") format("woff2"),
       url("../../fonts/landing/Flaticon.woff") format("woff"),
       url("../../fonts/landing/Flaticon.ttf") format("truetype"),
       url("../../fonts/landing/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../../fonts/landing/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-style: normal;
}

.flaticon-left-arrow:before { content: "\f100"; }
.flaticon-right-arrow:before { content: "\f101"; }
.flaticon-play-button:before { content: "\f102"; }
.flaticon-quote:before { content: "\f103"; }
.flaticon-clock:before { content: "\f104"; }
.flaticon-world:before { content: "\f105"; }
.flaticon-mail:before { content: "\f106"; }