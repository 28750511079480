/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Core

@import "core/custom/functions";
@import "core/custom/variables";
@import "core/custom/mixins";

// bootstrap (4.3.1) components

@import "core/bootstrap/root";
@import "core/bootstrap/reboot";
@import "core/bootstrap/type";
@import "core/bootstrap/images";
@import "core/bootstrap/code";
@import "core/bootstrap/grid";
@import "core/bootstrap/tables";
@import "core/bootstrap/forms";
@import "core/bootstrap/buttons";
@import "core/bootstrap/transitions";
@import "core/bootstrap/dropdown";
@import "core/bootstrap/button-group";
@import "core/bootstrap/input-group";
@import "core/bootstrap/custom-forms";
@import "core/bootstrap/nav";
@import "core/bootstrap/navbar";
@import "core/bootstrap/card";
@import "core/bootstrap/breadcrumb";
@import "core/bootstrap/pagination";
@import "core/bootstrap/badge";
@import "core/bootstrap/jumbotron";
@import "core/bootstrap/alert";
@import "core/bootstrap/progress";
@import "core/bootstrap/media";
@import "core/bootstrap/list-group";
@import "core/bootstrap/close";
@import "core/bootstrap/modal";
@import "core/bootstrap/tooltip";
@import "core/bootstrap/popover";
@import "core/bootstrap/carousel";
@import "core/bootstrap/utilities";
@import "core/bootstrap/print";

// Argon utilities and components

@import "core/custom/reboot";
@import "core/custom/utilities";
@import "core/custom/components";

// Vendor (Plugins)

@import "core/custom/vendors";

// Docs components

@import "core/docs/variables";
@import "core/docs/nav";
@import "core/docs/clipboard-js";
@import "core/docs/component-examples";
@import "core/docs/prism";
@import "core/docs/content";
@import "core/docs/sidebar";
@import "core/docs/footer";

// React Differences
@import "react/react-differences";