/*!
 * SlickNav Responsive Mobile Menu v1.0.10
 * (c) 2016 Josh Cope
 * licensed under MIT
 */
.slicknav_btn {
  position: relative;
  display: block;
  vertical-align: middle;
  float: right;
  padding: 0.438em 0.625em 0.438em 0.625em;
  line-height: 1.125em;
  cursor: pointer; }
  .slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
    margin-top: 0.188em; }

.slicknav_menu {
  *zoom: 1; }
  .slicknav_menu .slicknav_menutxt {
    display: block;
    line-height: 1.188em;
    float: left; }
  .slicknav_menu .slicknav_icon {
    float: left;
    width: 1.125em;
    height: 0.875em;
    margin: 0.188em 0 0 0.438em; }
    .slicknav_menu .slicknav_icon:before {
      background: transparent;
      width: 1.125em;
      height: 0.875em;
      display: block;
      content: "";
      position: absolute; }
  .slicknav_menu .slicknav_no-text {
    margin: 0; }
  .slicknav_menu .slicknav_icon-bar {
    display: block;
    width: 1.125em;
    height: 0.125em;}
  .slicknav_menu:before {
    content: " ";
    display: table; }
  .slicknav_menu:after {
    content: " ";
    display: table;
    clear: both; }

.slicknav_nav {
  clear: both; }
  .slicknav_nav ul {
    display: block; }
  .slicknav_nav li {
    display: block; }
  .slicknav_nav .slicknav_arrow {
    font-size: 0.8em;
    margin: 0 0 0 0.4em; }
  .slicknav_nav .slicknav_item {
    cursor: pointer; }
    .slicknav_nav .slicknav_item a {
      display: inline; }
  .slicknav_nav .slicknav_row {
    display: block; }
  .slicknav_nav a {
    display: block; }
  .slicknav_nav .slicknav_parent-link a {
    display: inline; }

.slicknav_brand {
  float: left; }

.slicknav_menu {
  font-size: 16px;
  box-sizing: border-box;
  background: #4c4c4c;
  padding: 5px; }
  .slicknav_menu * {
    box-sizing: border-box; }
  .slicknav_menu .slicknav_menutxt {
    color: #fff;
    font-weight: bold; }
  .slicknav_menu .slicknav_icon-bar {
    background-color: #fff; }

    .slicknav_btn {
      margin: 5px 5px 6px;
      text-decoration: none;
      background-color: none;
    }

.slicknav_nav {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 0.875em;
  list-style: none;
  overflow: hidden; }
  .slicknav_nav ul {
    list-style: none;
    overflow: hidden;
    padding: 0;
    margin: 0 0 0 20px; }
  .slicknav_nav .slicknav_row {
    padding: 5px 10px;
    margin: 2px 5px; }
    .slicknav_nav .slicknav_row:hover {
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      background: #ccc;
      color: #fff; }
  .slicknav_nav a {
    padding: 5px 10px;
    margin: 2px 5px;
    text-decoration: none;
    color: #fff; }
    .slicknav_nav a:hover {
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 0px;
      background: #ccc;
      color: #222; }
  .slicknav_nav .slicknav_txtnode {
    margin-left: 15px; }
  .slicknav_nav .slicknav_item a {
    padding: 0;
    margin: 0; }
  .slicknav_nav .slicknav_parent-link a {
    padding: 0;
    margin: 0; }

.slicknav_brand {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  padding: 7px 12px;
  height: 44px; }



  /*===== mobile menu slicknav =====*/

.mobile_menu {
	position: absolute;
	right: 10px;
	width: 96%;
	z-index: 99;
}
.slicknav_menu .slicknav_menutxt {
    display: none;
}
.slicknav_menu {
    background: transparent;
    margin-top: 10px;
}
.slicknav_menu .slicknav_icon-bar {
    background-color: #000;
    height: 3px;
    margin: 5px 0;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    width: 30px;
    position: relative;
}
.slicknav_btn {
	background-color: transparent;
	cursor: pointer;
	margin-bottom: 10px;
	margin-top: -35px;
	position: relative;
	z-index: 99;
}
/* .slicknav_menu .slicknav_open .slicknav_icon-bar:nth-child(2) {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.slicknav_menu .slicknav_open .slicknav_icon-bar:nth-child(1) {
    -webkit-transform: rotate(45deg) translate(1px, 7px);
    -ms-transform: rotate(45deg) translate(1px, 7px);
        transform: rotate(45deg) translate(1px, 7px);
}
.slicknav_menu .slicknav_open .slicknav_icon-bar:nth-child(3) {
    -webkit-transform: rotate(-45deg) translateY(-6px);
    -ms-transform: rotate(-45deg) translateY(-6px);
        transform: rotate(-45deg) translateY(-6px);
        position: relative;
        top: -1px;
} */
.slicknav_menu {
  margin: 0;
  padding: 0;
}
.slicknav_icon-bar {
    background: #000 !important;
}
.slicknav_nav {
	background: #fff;
	float: right;
	margin-top:0;
	padding: 0;
	width: 100%;
	border-bottom: 1px solid #eee;
}

.slicknav_nav a:hover {
    background: #F79960 none repeat scroll 0 0;
    border-radius: 0;
    color: #ffffff;
}

.slicknav_nav a {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    text-transform: capitalize;
}

.slicknav_nav .slicknav_arrow {
    float: right;
}

.slicknav_nav .slicknav_row:hover,
.slicknav_nav .slicknav_row:hover .slicknav_arrow {
    border-radius: 0;
    background-color: #F79960;
    background-color: transparent;
    color: #000;
}

.slicknav_btn {
	background-color: transparent;
	cursor: pointer;
	margin-bottom: 10px;
	position: relative;
	z-index: 99;
	border: none;
	border-radius: 3px;
  top: 5px;
  padding: 5px;
  right: 5px;
  margin-top: -5px;
  top: -36px;
}
.slicknav_menu .slicknav_icon {
	margin-right: 6px;
	margin-top: 3px;
	position: relative;
	right: 5px;
	top: -5px;
	padding-bottom: 3px;
}