/* [Master Stylesheet] */

/* --------------------------------------------
:: Template Name: Nilan App Landing Template
:: Version: v1.1
-------------------------------------------- */

/* =========== [Master Stylesheet] ============

 :: Reboot CSS
 :: Preloader CSS
 :: Header Area CSS
 :: Welcome Area CSS
 :: Benifits Area CSS
 :: Video Area CSS
 :: Single Feature Area CSS
 :: Call to action Area CSS
 :: Set up Area CSS
 :: Price Table Area CSS
 :: Why We Are Area CSS
 :: Single Blog Main Page Area CSS
 :: Counter up Area CSS
 :: Team Member Area CSS
 :: Contact Action Area CSS
 :: Footer Contact CSS
 :: Client Area CSS
 :: Subscribe Area CSS
 :: Contact Support Area CSS
 :: Frequently asked question Area CSS
 :: Download Are css
 :: Tab Are css
 :: Breadcrumb area
 :: Blog Details
 :: Comments Area CSS
 :: Blog Part Area CSS
 :: Responsive CSS

===================================== */

/* Import Fonts & All CSS */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;0,600;1,400&family=Roboto:wght@400;500&display=swap');

@import 'bootstrap.min.css';
@import 'owl.carousel.min.css';
@import 'magnific-popup.css';
@import 'font-awesome.min.css';
@import 'themify-icons.css';
@import 'nice-select.css';
@import 'flaticon.css';
@import 'animate.css';
@import 'aos.css';
@import 'custom/animate.css';
@import 'slicknav.css';
@import 'slick.css';
@import 'jquery.animatedheadline.css';
@import 'boxicons.min.css';


/* :: Reboot CSS */

.landing body {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #696687;
    background-color: #f1f6fd;
}

.landing .img {
    max-width: 100%;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s
}

.landing li {
    list-style: none;
}

.landing h1,
.landing h2,
.landing h3,
.landing h4,
.landing h5,
.landing h6 {
    font-family: 'Poppins', sans-serif;
    color: #22105f;
    font-weight: 600;
    line-height: 1.3;
}

.landing a,
.landing a:hover,
.landing a:focus,
.landing a:active {
    font-weight: 500;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
}

.landing ul {
    margin: 0px;
    padding: 0px
}

.landing li {
    list-style: none
}

.landing p {
    font-size: 16px;
    color: #696687;
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    line-height: 1.6;
}

.landing label {
    font-size: 16px;
    font-weight: 500;
    color: #22105f;
}

.landing img {
    max-width: 100%;
    height: auto;
    -webkit-transition-delay: .1s;
    -o-transition-delay: .1s;
    transition-delay: .1s;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    -webkit-transition-duration: .7s;
    -o-transition-duration: .7s;
    transition-duration: .7s;
    -webkit-transition-property: all;
    -o-transition-property: all;
    transition-property: all;
}

.landing .section-padding-350-50 {
    padding-top: 350px;
    padding-bottom: 50px;
}

.landing .section-padding-0-100 {
    padding-top: 0;
    padding-bottom: 100px;
}

.landing .section-padding-0-70 {
    padding-top: 0;
    padding-bottom: 70px;
}

.landing .section-padding-0-50 {
    padding-top: 0;
    padding-bottom: 50px;
}

.landing .section-padding-200 {
    padding-top: 200px;
    padding-bottom: 200px;
}

.landing .section-padding-100-50 {
    padding-top: 100px;
    padding-bottom: 50px;
}

.landing .section-padding-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.landing .section-padding-0-50 {
    padding-top: 0;
    padding-bottom: 50px;
}

.landing .section-padding-100-0 {
    padding-top: 100px;
    padding-bottom: 0;
}

.landing .mb-65 {
    margin-bottom: 67px
}

.landing .mb-15 {
    margin-bottom: 15px !important;
}

.landing .mb-30 {
    margin-bottom: 30px;
}

.landing .mt-60 {
    margin-top: 60px;
}

.landing .mt-80 {
    margin-top: 80px;
}

.landing .mb-20 {
    margin-bottom: 20px;
}

.landing .mb-70 {
    margin-bottom: 70px;
}

.landing .mb-100 {
    margin-bottom: 100px;
}

.landing .black-bg {
    background: #222222 !important
}

.landing .white-bg {
    background: #ffffff
}


.landing .bg-img {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.landing .bg-overlay {
    position: relative;
    z-index: 1;
}

.landing .bg-overlay::before {
    position: absolute;
    content: "";
    background-color: #4527a4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.85;
}

.landing .bg-overlay-2 {
    position: relative;
    z-index: 1;
}

.landing .bg-overlay-2::before {
    position: absolute;
    content: "";
    background-color: #4527a4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.70;
}

.landing .bg-overlay-3 {
    position: relative;
    z-index: 1;
}

.landing .bg-overlay-3::before {
    position: absolute;
    content: "";

    background-color: #4527a4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.60;
    border-radius: 15px 15px 0 0;
}

.landing .bg-overlay-4 {
    position: relative;
    z-index: 1;
}

.landing .bg-overlay-4::before {
    position: absolute;
    content: "";

    background-color: #4527a4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.30;
}

.landing #scrollUp {
    width: 35px;
    height: 35px;
    background-color: #22105f;
    bottom: 30px;
    right: 30px;
    color: #fff;
    text-align: center;
    font-size: 24px;
    line-height: 38px;
    -webkit-box-shadow: 0 1px 26px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 26px 0 rgba(0, 0, 0, 0.15);
}

.landing .theme-bg {
    background: #4527a4;

}

.landing .opacity-6 {
    opacity: 0.6;
}

.landing .opacity-1 {
    opacity: 0.1;
}

.landing .overlay2 {
    position: relative;
    z-index: 0
}

.landing .overlay2::before {
    position: absolute;
    content: "";
    background-color: #222222;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.5
}

.landing .pt-120 {
    padding-top: 120px
}

.landing .mt-20 {
    margin-top: 20px;
}

.landing .mt-30 {
    margin-top: 30px;
}

.landing .mt-50 {
    margin-top: 50px;
}

.landing .mb-15 {
    margin-bottom: 15px;
}

.landing .mt-we-70 {
    margin-top: 70px;
}

.landing .section-padding-100-70 {
    padding-top: 100px;
    padding-bottom: 70px;
}

.landing .section-padding-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.landing .bg-primary-cu {
    background-color: #4527a4;
    opacity:0.96;
}

.landing .btn-box a {
    position: relative;
    display: inline-block;
    overflow: hidden;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 30px;
    text-align: center;
    padding: 13px 50px;
    z-index: 1;
}

.landing .btn-box a::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: #fff;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scale(0.2, 1);
    -ms-transform: scale(0.2, 1);
    transform: scale(0.2, 1);
    z-index: -1;
}

.landing .btn-box a:hover::before {
    opacity: 1;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
}

.landing .btn-box a:hover {
    color: #4527a4;
}

.landing .sosa-btn {
    background: #4527a4;
    height: 46px;
    min-width: 150px;
    line-height: 46px;
    color: #fff;
    text-align: center;
    display: inline-block;
    padding: 0;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-size: 14px;
    text-transform: capitalize;
    border-radius: 22.5px;
    -webkit-transition-duration: 400ms;
    -o-transition-duration: 400ms;
    transition-duration: 400ms;
}

.landing .sosa-btn:hover {
    background-color: #4527a4;
    color: #fff;
}

.landing .hero-btn-video {
    background-color: #fff;
    height: 46px;
    min-width: 150px;
    line-height: 46px;
    color: #333;
    text-align: center;
    display: inline-block;
    padding: 0;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-size: 15px;
    text-transform: capitalize;
    border-radius: 30px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.landing .hero-btn-video:hover {
    color: #4527a4;
}

.landing .btn.focus,
.landing .btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.landing .sosa-btn-2 {
    background-color: transparent;
    height: 46px;
    min-width: 150px;
    line-height: 44px;
    color: #fff;
    border: 1px solid #fff;
    text-align: center;
    display: inline-block;
    padding: 0;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-size: 15px;
    text-transform: capitalize;
    border-radius: 30px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.landing .sosa-btn-2:hover {
    background-color: #fff;
    border-color: #fff;
    color: #4527a4;
    -webkit-box-shadow: 0px 0px 7px 4px rgba(0, 0, 0, 0.17);
    box-shadow: 0px 0px 7px 4px rgba(0, 0, 0, 0.17);
}

.landing .underline-hover {
    color: #4527a4;
    font-weight: 400;
    font-size: 16px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s
}

.landing .underline-hover:hover {
    text-decoration: underline;
    color: #2d1fb4
}

.landing .theme-btn-two {
    position: relative;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background-color: #4527a4;
    border-radius: 2px;
    text-align: center;
    border: 1px solid#4527a4;
    height: 46px;
    min-width: 150px;
    line-height: 44px;
    padding: 0;
    cursor: pointer;
    z-index: 1;
    -webkit-transition-duration: 400ms;
    -o-transition-duration: 400ms;
    transition-duration: 400ms;
}

.landing .theme-btn-two:hover {
    color: #4527a4;
    background-color: transparent;
    font-weight: 500;
}

.landing .fill-primary {
    fill: #4527a4;
}

.landing .fill-white {
    fill: #4527a4;
}

.landing .bg-gra {
    background: -o-linear-gradient(top, #c8e7ff 0%, #fff 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#c8e7ff), to(#fff));
    background: linear-gradient(180deg, #c8e7ff 0%, #fff 100%);
}

.landing .position-absolute- {
    position: absolute !important;
    bottom: -7px;
    left: 0;
}

.landing .position-absolute-2 {
    position: absolute !important;
    bottom: 0;
    left: -95px;
}

.landing .section-title {
    margin-bottom: 70px;
}

.landing .section-title p {
    font-size: 16px;
    margin-bottom: 0;
}

.landing .section-title.t_center {
    text-align: center;
}

.landing .section-title h6 {
    position: absolute;
    left: 0px;
    right: 0;
    font-size: 100px;
    top: -40px;
    color: #eef0fe;
    z-index: -1;
    font-weight: 500;
}

.landing .section-title h5 {
    font-size: 15px;
    margin: 0;
    text-transform: capitalize;
    color: #4527a4;
    margin: 0 0 20px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.landing .section-title h2 {
    font-size: 42px;
    margin-bottom: 8px;
    text-transform: capitalize;
    margin-top: 0;
}

@media (min-width: 480px) and (max-width: 575px) {
    .landing .section-heading h4 {
        font-size: 28px;
        line-height: 1.3;
    }

    .landing .section-heading h6 {
        margin-bottom: 6px;
    }
}


.landing .max-width-none-cu {
    max-width: none;
}

.landing .mb-50 {
    margin-bottom: 50px;
}

.landing .font-16 {
    font-size: 16px;
}

.landing .welcome-btn {
    height: 50px;
    min-width: 150px;
    line-height: 50px;
    padding: 0;
    text-align: center;
    display: inline-block;
    font-size: 15px;
    color: #fff;
    background-color: #eb2f06;
    border-radius: 2px;
    font-weight: 500;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    border-radius: 5px;
}

.landing .welcome-btn:hover {
    color: #222;
    background-color: #fff;
}

/* :: Preloader CSS */

.landing #droba-loader {
    position: fixed;
    background-color: #fff;
    z-index: 99999999999999999;
    height: 100%;
    width: 100%;
    -webkit-transition: .1s all ease;
    -o-transition: .1s all ease;
    transition: .1s all ease;
    -webkit-transition-delay: .7s;
    -o-transition-delay: .7s;
    transition-delay: .7s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.landing #droba-loader.loaded {
    opacity: 0;
    visibility: hidden;
}

.landing #droba-loader:before,
.landing #droba-loader:after {
    content: "";
    position: absolute;
    height: 50%;
    width: 100%;
    background-color: #4527a4;
    -webkit-transition: .7s all ease;
    -o-transition: .7s all ease;
    transition: .7s all ease;
}

.landing #droba-loader:before {
    top: 0;
    left: 0;
}

.landing #droba-loader:after {
    bottom: 0;
    left: 0;
}

.landing #droba-loader.loaded:before,
.landing #droba-loader.loaded:after {
    height: 0%;
}

.landing .lds-default {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    z-index: 99999;
}

.landing .lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50%;
    -webkit-animation: lds-default 1.2s linear infinite;
    animation: lds-default 1.2s linear infinite;
}

.landing .lds-default div:nth-child(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    top: 37px;
    left: 66px;
}

.landing .lds-default div:nth-child(2) {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
}

.landing .lds-default div:nth-child(3) {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
}

.landing .lds-default div:nth-child(4) {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
}

.landing .lds-default div:nth-child(5) {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
}

.landing .lds-default div:nth-child(6) {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
}

.landing .lds-default div:nth-child(7) {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
}

.landing .lds-default div:nth-child(8) {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
}

.landing .lds-default div:nth-child(9) {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
}

.landing .lds-default div:nth-child(10) {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
}

.landing .lds-default div:nth-child(11) {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
    top: 62px;
    left: 52px;
}

.landing .lds-default div:nth-child(12) {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
}

@-webkit-keyframes lds-default {

    0%,
    20%,
    80%,
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
}

@keyframes lds-default {

    0%,
    20%,
    80%,
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
}


/* :: Header Area CSS */

.landing .header-area {
    position: relative;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    z-index: 2;

    background-color: #4527a4;
    opacity:0.96;
}

@media (max-width: 767px) {
    .landing .header-area {
        padding-top: 0;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .landing .header-area {
        padding-top: 0;
    }
}

.landing .header-area .main-header-area .appointment {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -ms-flex-pack: flex-end;
}

.landing .header-area .main-header-area .appointment .socail_links ul li {
    display: inline-block;
}

.landing .header-area .main-header-area .appointment .socail_links ul li a {
    color: #A8A7A0;
    margin: 0 10px;
    font-size: 15px;
}

.landing .header-area .main-header-area .appointment .socail_links ul li a:hover {
    color: #fff;
}


.landing .header-area .main-header-area .appointment .book_btn a:hover {
    background: #fff;
    color: #234262;
    border: 1px solid #234262;
}

.landing .header-area .main-header-area .main-menu {
    text-align: center;
}

.landing .header-area .main-header-area .main-menu ul li {
    display: inline-block;
    position: relative;
    margin: 0 11px;
}

.landing .header-area .main-header-area .main-menu ul li a {
    color: rgba(255, 255, 255, 0.95);
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 500;
    display: inline-block;
    padding: 30px 0px 30px 0px;
    position: relative;
    text-transform: capitalize;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .landing .header-area .main-header-area .main-menu ul li a {
        font-size: 14px;
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .landing .header-area .main-header-area .main-menu ul li a {
        font-size: 14px;
    }
}

.landing .header-area .main-header-area .main-menu ul li a i {
    font-size: 9px;
}

@media (max-width: 767px) {
    .landing .header-area .main-header-area .main-menu ul li a i {
        display: none !important;
    }

}

@media (min-width: 768px) and (max-width: 991px) {
    .landing .header-area .main-header-area .main-menu ul li a i {
        display: none !important;
    }

}

.landing .slicknav_icon-bar {
    background-color: #fff !important;
}

.landing .header-area .main-header-area .main-menu ul li .submenu {
    position: absolute;
    left: 0;
    top: 120%;
    background: #fff;
    width: 200px;
    z-index: 2;
    -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.06), -2px 0px 5px 1px rgba(0, 0, 0, 0.06);
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.06), -2px 0px 5px 1px rgba(0, 0, 0, 0.06);
    opacity: 0;
    visibility: hidden;
    text-align: left;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
    border-radius: 3px;
}

.landing .header-area .main-header-area .main-menu ul li .submenu li {
    display: block;
}

.landing .header-area .main-header-area .main-menu ul li .submenu li a {
    padding: 10px 15px;
    position: inherit;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    display: block;
    color: #111;
    font-size: 15px;
}

.landing .header-area .main-header-area .main-menu ul li .submenu li a:hover {
    color: #4527a4;
}

.landing .mobile_menu {
    top: 0;
}

.landing .slicknav_nav {
    border-radius: 3px;
    padding: 15px;
    -webkit-box-shadow: 0px 11px 20px 0px rgba(38, 59, 94, 0.2);
    box-shadow: 0px 11px 20px 0px rgba(38, 59, 94, 0.2);
    top: -22px;
    position: relative;
}

.landing .slicknav_nav a {
    font-size: 14px;
    font-weight: 500;
    color: #111;
    border-bottom: 1px solid #e9e9e9;
    padding: 8px 15px;
}

.landing .slicknav_nav .slicknav_item a {
    border: none;
}

.landing .slicknav_menu .slicknav_nav a:hover {
    background: transparent;
    color: #4527a4;
}

.landing .slicknav_nav .slicknav_arrow {
    font-size: 20px;
    margin: 0;
    line-height: 20px;
}

.landing .slicknav_nav .slicknav_row {
    padding: 8px 15px;
    margin: 2px 5px;
}

.landing .slicknav_item.slicknav_row i {
    display: none;
}

.landing .logo a img {
    width: 50px;
}

.landing .header-area .main-header-area .main-menu ul li .submenu li a::before {
    display: none;
}

.landing .header-area .main-header-area .main-menu ul li .submenu li:hover a {
    color: #4527a4;
}

.landing .header-area .main-header-area .main-menu ul li:hover > .submenu {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

.landing .header-area .main-header-area .main-menu ul li:first-child a {
    padding-left: 0;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .landing .header-area .main-header-area .main-menu ul li:last-child a {
        margin-right: 30px;
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .landing .header-area .main-header-area .main-menu ul li:last-child a {
        margin-right: 30px;
    }
}

.landing .header-area .main-header-area.sticky {
    position: fixed;
    width: 100%;
    top: -70px;
    left: 0;
    right: 0;
    z-index: 9;
    -webkit-transform: translateY(70px);
    -ms-transform: translateY(70px);
    transform: translateY(70px);
    -webkit-transition: background 500ms ease, -webkit-transform 500ms ease;
    transition: background 500ms ease, -webkit-transform 500ms ease;
    -o-transition: transform 500ms ease, background 500ms ease;
    transition: transform 500ms ease, background 500ms ease;
    transition: transform 500ms ease, background 500ms ease, -webkit-transform 500ms ease;
    -webkit-transition: transform 500ms ease, background 500ms ease;
    -webkit-box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);

    background-color: #4527a4;
}

.landing .header-area .main-header-area.sticky .main-menu ul li .submenu {
    background-color: #fff;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.landing .header-area .main-header-area.sticky .main-menu ul li .submenu li a {
    color: #111;
}

.landing .header-area .main-header-area.sticky .main-menu ul li .submenu li:hover a {
    color: #4527a4;
}

@media (max-width: 767px) {
    .landing .header-area .main-header-area.sticky {
        padding: 15px 10px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .landing .header-area .main-header-area.sticky {
        padding: 15px 10px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .landing .header-area .main-header-area.sticky {
        padding: 0px 20px;
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .landing .header-area .main-header-area.sticky {
        padding: 0px 20px;
    }
}

.landing .header-area .main-header-area.sticky .main-menu {
    padding: 0;
}

.landing .header-area .header-top_area {
    padding: 11px 150px;
    background: #232637;
}

@media (max-width: 767px) {
    .landing .header-area .header-top_area {
        padding: 10px 10px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .landing .header-area .header-top_area {
        padding: 10px 10px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .landing .header-area .header-top_area {
        padding: 11px 50px;
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .landing .header-area .header-top_area {
        padding: 11px 50px;
    }
}

@media (max-width: 767px) {
    .landing .header-area .header-top_area .header_top_wrap {
        display: block !important;
        text-align: center;
    }
}

.landing .header-area .header-top_area .header_top_wrap p {
    margin-bottom: 0;
    font-size: 14px;
    color: #ffffff;
}

.landing .header-area .header-top_area .header_top_wrap p span {
    color: #fff;
}

.landing .header-area .header-top_area .header_top_wrap p span i {
    margin-right: 3px;
}

.landing .header-area .header-top_area .header_top_wrap .text_wrap p a {
    color: #fff;
    font-size: 14px;
}

.landing .header-area .header-top_area .header_top_wrap .text_wrap a:last-child {
    margin-left: 30px;
    padding-left: 30px;
    position: relative;
}

@media (max-width: 767px) {
    .landing .header-area .header-top_area .header_top_wrap .text_wrap a:last-child {
        margin-left: 10px;
        padding-left: 10px;
        position: relative;
    }
}

.landing .header-area .header-top_area .header_top_wrap .text_wrap a:last-child::before {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 13px;
    background: #fff;
    content: '';
}

.landing .btn.dropdown-toggle {
    -webkit-transition-duration: 700ms;
    -o-transition-duration: 700ms;
    transition-duration: 700ms;
    color: rgba(255, 255, 255, 0.95);
    font-weight: 400;
}
.landing .btn.dropdown-toggle-contact {
    -webkit-transition-duration: 700ms;
    -o-transition-duration: 700ms;
    transition-duration: 700ms;
    color: #222;
    font-weight: 400;
}

.landing .header-area .main-header-area .main-menu ul li .btn.header-btn {
    background-color: #fff;
    padding: 10px 20px;
    font-size: 14px;
    color: #222;
    font-weight: 600;
    border-radius:30px;
    -webkit-transition-duration: 200ms;
    -o-transition-duration: 200ms;
    transition-duration: 200ms;
}

.landing .btn.header-btn:hover {
    background-color: #333;
    color: #fff;
}

.landing .btn.header-btn.two {
    background-color: #4527a4;
    color: #fff;
}

.landing .btn.header-btn.two:hover {
    background-color: #333;
    color: #fff;
}

.landing .dropdown-item {
    font-weight: 500;
    font-size: 14px;
    color:#222 !important;
    align-content:center;
}

.landing .dropdown-item:focus,
.landing .dropdown-item:hover {
    color: #eb2f06;
    -webkit-transition-duration: 400ms;
    -o-transition-duration: 400ms;
    transition-duration: 400ms;
}

.landing .header-area.home-2 .main-header-area {
    background-color: transparent;
}

.landing .header-area.home-2 {
    position: absolute;
}

.landing .header-area.home-5 {
    position: absolute;
}

.landing .header-area.home-5 .main-header-area {
    background-color: transparent;
}

.landing .header-area.home-2 .main-header-area.sticky {
    background-color: #4527a4;
}

.landing .header-area.home-5 .main-header-area.sticky {
    background-color: #4527a4;
}

.landing .header-area.home-3 {
    border-bottom: none;
    position: fixed;
    background: transparent;
}

.landing .header-area.home-3 .main-header-area {
    background-color: transparent;
}

.landing .header-area.home-3 .main-header-area .main-menu ul li a {
    color: #111;
}

.landing .header-area.home-3 .btn.dropdown-toggle {
    color: #111;
}

.landing .header-area.home-3 .slicknav_icon-bar {
    background-color: #2d1fb4 !important;
}

.landing .welcome-area.four {
    height: 800px;
}

/* ---- Single Menu css ---- */

.landing .custom_nav_menu {
    padding: 15px 0px;
    width: 100%;
    border-radius: 0px;
    z-index: 999;
    margin-bottom: 0px;
    background-color: #4527a4;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    border-bottom: 1px solid rgba(96, 70, 254, 0.80);
}

.landing .custom_nav_menu .social_menu .list-inline-item:not(:last-child) {
    margin-right: 8px;
}

.landing .custom_nav_menu .navbar-nav li a {
    color: #fff;
    font-size: 15px;
    background-color: transparent !important;
    padding: 15px 0;
    margin: 0 15px;
    font-weight: 500;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    cursor: pointer;
}

.landing .navbar-toggler {
    font-size: 28px;
    margin-top: 5px;
    margin-bottom: 0px;
    color: #ffffff;
}

.landing .stickyadd .navbar-nav {
    margin-top: 0px;
}

.landing .stickyadd.custom_nav_menu .navbar-nav li a {
    color: #ffffff;
}

.landing .custom_nav_menu .navbar-brand.logo img {
    height: 26px;
}

.landing .stickyadd.custom_nav_menu {
    margin-top: 0px;
    padding: 8px 0px;
    background-color: #4527a4;
    -webkit-box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
}

.landing .custom_nav_menu .navbar-nav li.active a,
.landing .custom_nav_menu .navbar-nav li a:hover,
.landing .custom_nav_menu .navbar-nav li a:active {
    color: #f52a50 !important;
}

.landing .navbar-toggle {
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 0px;
    color: #ffffff;
}

.landing .custom_nav_menu .btn-custom {
    margin-top: 5px;
    margin-bottom: 5px;
}

.landing .nav .open > a,
.landing .nav .open > a:focus,
.landing .nav .open > a:hover {
    background-color: transparent;
    border-color: #337ab7;
}

.landing .logo .logo-light {
    display: inline-block;
}

.landing .stickyadd .logo .logo-dark {
    display: inline-block;
}

.landing .stickyadd .logo .logo-light {
    display: none;
}

.landing .logo .logo-dark {
    display: none;
}

.landing .custom_nav_menu.home-2 {
    background-color: transparent;
}

.landing .stickyadd.custom_nav_menu.home-2 {
    border-bottom: none;
}

.landing .custom_nav_menu.home-3.sticky {
    background-color: transparent;
    border-bottom: none;
}

.landing .custom_nav_menu.home-3 .navbar-nav li a {
    color: #111;
}

.landing .custom_nav_menu.home-3 .btn.dropdown-toggle {
    color: #111;
}

.landing .navbar.navbar-expand-lg.fixed-top.custom_nav_menu.home-3.sticky.stickyadd {
    background-color: #fff;
}

.landing .navbar-brand.logo {
    padding-top: 0;
}

.landing .custom_nav_menu.home-5.sticky {
    background-color: transparent;
}

.landing .navbar.navbar-expand-lg.fixed-top.custom_nav_menu.home-5.sticky.stickyadd {
    background-color: #4527a4;
    border-bottom: none;

}

.landing .navbar.navbar-expand-lg.fixed-top.custom_nav_menu.home-2.sticky.stickyadd {
    background-color: #4527a4;
    border-bottom: none;
}

.landing .header-area.home-3 .main-header-area.sticky {
    background: -o-linear-gradient(45deg, #ffffff, #ffffff);
    background: linear-gradient(45deg, #ffffff, #ffffff)
}


/* :: Welcome Area CSS */

.landing .welcome-area {
    height: 800px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.landing .welcome-area.home-2 {
    height: 1000px;
}

.landing .welcome-area.home-3 {
    height: 900px;
}

.landing .welcome-area.home-5 {
    height: 900px;
}

.landing .hero-pattern-bg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

.landing .hero-image-3 {
    position: relative;
    z-index: 2;
    padding-left: 50px;
}

.landing .hero-shape-3 {
    position: absolute;
    width: 650px;
    left: 0px;
    top: -40px;
    background-repeat: no-repeat;
    -webkit-animation: zoom-fade 15s infinite linear;
    animation: zoom-fade 15s infinite linear;
}

.landing .single-hero-slide h2 {
    font-weight: 600;
    font-size: 48px;
    margin-bottom: 15px;
}

.landing .single-hero-slide.two h2 {
    position: relative;
    z-index: 1;
    padding-bottom: 20px;
}

.landing .single-hero-slide.two h2::after {
    position: absolute;
    content: "";

    background-color: #4527a4;
    height: 5px;
    width: 100px;
    bottom: 0;
    left: 0;
}

.landing .hero-bubble-shape {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 0.70;
}

.landing .single-hero-slide span {
    font-size: 18px;
    margin-bottom: 15px;
    color: #4527a4;
    display: block;
}

.landing .single-hero-slide p {
    margin-bottom: 40px;
    font-size: 18px;
    display: block;
}

.landing .hero-image-3 img {
    border-radius: 10px 100px 10px 100px;
}


.landing .welcome-content p {
    font-size: 20px;
}

.landing .welcome-content {
    position: relative;
    z-index: 9;
}

.landing .welcome-content.home-2 h3 {
    font-size: 52px;
}

.landing .welcome-banner-shape {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    margin: auto;
    z-index: 1;
}

.landing .welcome-thumb-2 {
    position: relative;
    z-index: 9;
}

.landing .welcome-area- .owl-nav .owl-prev,
.landing .welcome-area- .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 22;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 26px;
    -webkit-box-shadow: 0 20px 40px rgba(42, 38, 45, .40);
    box-shadow: 0 20px 40px rgba(42, 38, 45, .40);
    color: #fff;
    height: 100px;
    width: 50px;
    line-height: 100px;
    text-align: center;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.landing .welcome-area- .owl-nav .owl-next {
    right: 0;
    left: auto;
    border-radius: 5px 0 0 5px;
}

.landing .welcome-area- .owl-nav .owl-prev {
    border-radius: 0 5px 5px 0;
}

.landing .welcome-area- .owl-nav .owl-prev:hover,
.landing .welcome-area- .owl-nav .owl-next:hover {
    background-color: #4527a4;
}

.landing .curve-image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}


.landing .welcome-bg-thumb {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.landing .welcome-bg-thumb-2 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.landing .welcome-content h3 {
    font-size: 48px;
    color: #fff;
    margin-bottom: 20px;
}

.landing .welcome-content.two h3 {
    font-size: 52px;
    font-weight: 600;
}

.landing .welcome-content p {
    font-size: 17px;
    color: #fff;
    font-weight: 200;
    margin-bottom: 40px;
}

.landing .welcome-thumb {
    top: 34%;
    left: -16%;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-name: bounce_fountainG;
    animation-name: bounce_fountainG;
    -webkit-animation-duration: 43s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: normal;
    animation-direction: normal;
}


.landing .animation-thumb {
    top: 34%;
    left: -16%;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    -webkit-animation-name: bounce_fountainG;
    animation-name: bounce_fountainG;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: normal;
    animation-direction: normal;
}

@-webkit-keyframes bounce_fountainG {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes bounce_fountainG {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}


.landing .p-10px {
    padding: 10px;
}

.landing .white-bg {
    background-color: #ffffff;
}


.landing .welcome-offer-list li {
    color: #fff;
    font-size: 13px;
}

.landing .welcome-offer-list li i {
    font-size: 11px;
}

.landing .welcome-content-3 h3 {
    font-size: 48px;
    font-weight: 600;
}

.landing .welcome-content-4 h6 {
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #4527a4;
}

.landing .welcome-content-4 h3 {
    font-size: 44px;
    font-weight: 600;
}

.landing .welcome-content-4 h5 {
    font-size: 18px;
    margin-bottom: 40px;
}


.landing .anim-icons .icon-17 {
    position: absolute;
    left: 0;
    bottom: 0;
    -webkit-animation: zoom-fade 5s infinite linear;
    animation: zoom-fade 5s infinite linear;
}

.landing .anim-icons .icon-18 {
    right: 0;
}


/* ----- About us area ----- */

.landing .about-area-content h2 {
    font-size: 42px;
    margin-bottom: 20px;
}

.landing .about-thumb {
    padding-right: 50px;
}

.landing .about-area-content-hosting h2 {
    font-size: 44px;
}

.landing .video-icon.home-5 {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 9;
    margin-top: 0;
    cursor: pointer;
}

.landing .video-icon.home-7 {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 9;
    margin-top: 0;
    cursor: pointer;
}

.landing .video-icon.home-7 a i {
    background-color: #fff;
    color: #4527a4;
    height: 80px;
    width: 80px;
    line-height: 80px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
}

.landing .video-icon.home-5 a i {
    background-color: #fff;
    color: #4527a4;
    height: 80px;
    width: 80px;
    line-height: 80px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
}

.landing .video-icon-home-6 {
    background-color: #fff;
    color: #4527a4;
    height: 80px;
    width: 80px;
    line-height: 80px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
}

.landing .video-content-text h2 {
    font-size: 36px;
    margin-bottom: 20px;
}

.landing .video-content-area {
    position: relative;
    z-index: 1;
}

.landing .video-thumb {
    position: relative;
    z-index: 1;
}

.landing .video-icon.home-6 a i {
    background: #fff;
    color: #4527a4;
    height: 80px;
    width: 80px;
    line-height: 80px;
    text-align: center;
    border-radius: 50%;
}

.landing .video-icon-2 {
    position: relative;
    z-index: 1;
    text-align: center;
    margin-left: 30px;
}

.landing .video-icon-2.home-7 a i {
    background: rgba(235, 47, 6, 0.15);
    color: #eb2f06;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
}

.landing .video-icon-2.home-7 a {
    color: #eb2f06;
}

.landing .video-btn::after {
    content: "";
    position: absolute;
    top: 0;
    height: 80px;
    width: 80px;
    line-height: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    -webkit-animation: pulse-border 1500ms ease-out infinite;
    animation: pulse-border 1500ms ease-out infinite;
    pointer-events: none;
    z-index: -1;
}

.landing .video-btn.white::after {
    background: #ffffff;
}

@-webkit-keyframes pulse-border {
    0% {
        -ms-transform: scale(0.8);
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 1;
    }

    100% {
        -ms-transform: scale(2);
        -webkit-transform: scale(2);
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes pulse-border {
    0% {
        -ms-transform: scale(0.8);
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 1;
    }

    100% {
        -ms-transform: scale(2);
        -webkit-transform: scale(2);
        transform: scale(2);
        opacity: 0;
    }
}

.landing .video-content h4 {
    color: #fff;
    font-size: 34px;
    line-height: 50px;
    margin-bottom: 20px;
}

.landing .video-content h4 span {
    font-weight: 400;
}

.landing .video-content p {
    font-size: 15px;
    color: #fff;
    margin-bottom: 0;
    width: 50%;
    margin: auto;
}

.landing .video-content-3 h4 {
    color: #fff;
    font-size: 40px;
    margin-bottom: 10px;
}

.landing .video-content-3 p {
    color: #fff;
    font-size: 18px;
}

.landing .call-to-action-btn a {
    padding: 8px 30px;
    text-align: center;
    display: inline-block;
    background-color: #2f23fd;
    color: #fff;
    font-size: 14px;
    border-radius: 3px;
    margin-top: 30px;
}

.landing .about-content-4 h2 {
    margin-bottom: 20px;
    font-size: 36px;
    font-weight: 600;
}

.landing .about-content-4 h2 span {
    color: #eb2f06;
}

.landing .why-area-content h2 {
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: 600;
}

.landing .we-know-content ul li {
    color: #22105f;
    font-size: 16px;
    margin-bottom: 15px;
}

.landing .we-know-content ul li i {
    font-size: 18px;
    margin-right: 5px;
}

.landing .hosting-about li {
    color: #22105f;
    margin-bottom: 15px;
    font-size: 16px;
}

/* ----- Home - 6 ----- */

.landing .subscribe-form-wrapper .newsletter-form {
    width: 450px;
}

.landing .newsletter-form .input-group > input[type="email"].form-control {
    border-radius: 30px 0 0 30px;
    text-indent: 10px;
}

.landing .solid-btn {
    color: #fff;
    -webkit-box-shadow: 0 20px 30px 0 rgba(67, 37, 204, .2);
    box-shadow: 0 20px 30px 0 rgba(67, 37, 204, .2);
    background-color: #4527a4;
    border-radius: 30px;
    padding: 0 20px;
}

.landing .solid-btn:hover {
    background: #2f23fd;
    color: #ffffff;
    border-color: #2f23fd;
}

/* ----- Animation ----- */

.landing .anim-icons .icon {
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
}

.landing .anim-icons .icon-1 {
    left: 110px;
    top: 110px;
    z-index: 1;
    opacity: 0.30;
}

.landing .anim-icons .icon-2 {
    left: 90px;
    top: 45%;
    z-index: 1;
}

.landing .anim-icons .icon-3 {
    left: 50%;
    top: 50%;
    z-index: 1;
}

.landing .anim-icons .icon-13 {
    top: 15%;
    right: 15%;
    z-index: 1;
}

.landing .rotate-me {
    animation-name: rotateme;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: rotateme;
    -webkit-animation-duration: 30s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: rotateme;
    -moz-animation-duration: 30s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: rotateme;
    -ms-animation-duration: 30s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: rotateme;
    -o-animation-duration: 30s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

/* ----- Service Area ----- */

.landing .single-item-3 {
    background-color: #fff;
    padding: 60px 30px;
    margin-bottom: 50px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.landing .single-item-3 .icon-box {
    font-size: 44px;
    color: #4527a4;
    margin-bottom: 20px;
}

.landing .single-item-3:hover {
    -webkit-box-shadow: 0 18px 56px rgba(47, 91, 234, 0.135);
    box-shadow: 0 18px 56px rgba(47, 91, 234, 0.135);
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
}


/* ---- Benifits area ---- */

.landing .single-benifit-area {
    -webkit-transition-duration: 700ms;
    -o-transition-duration: 700ms;
    transition-duration: 700ms;
    padding: 30px;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    -webkit-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.12);
}

.landing .single-benifit-area::after {
    position: absolute;
    content: "";
    z-index: 2;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 0;
    background-color: #4527a4;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.landing .single-benifit-area .icon i {
    height: 50px;
    width: 50px;
    background-color: #4527a4;
    line-height: 50px;
    color: #fff;
    text-align: center;
    font-size: 24px;
    border-radius: 3px;
    margin-bottom: 20px;
}

.landing .single-benifit-area:hover::after {
    width: 100%;
}

.landing .single-benifit-area:hover {
    background-color: #fff;
    -webkit-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    transform: translateY(-3px);
}

.landing .we-offer-content-3 h3 {
    font-size: 44px;
}

.landing .service-benefit {
    margin-bottom: 25px;
}

.landing .service-benefit:last-child {
    margin-bottom: 0;
}

.landing .single-benefit-text p {
    margin-bottom: 0;
}

.landing .service-benefit .icon-area {
    font-size: 36px;
    margin-right: 20px;
    color: #4527a4;
}

.landing .we-offer-thumb-3 {
    padding-left: 50px;
}

.landing .single-hosting-text h4 {
    font-size: 22px;
}

/* ---- Home -3 ---- */

.landing .single-benifit-area .icon-2 i {
    color: #4527a4;
    text-align: center;
    font-size: 44px;
    margin-bottom: 20px;
}

.landing .single-benifit-area.home-3 {
    -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

/* ---- Home -4 ---- */

.landing .single-benifit-area .icon.home-4 i {
    background-color: #4527a4;
    text-align: center;
    font-size: 30px;
    height: 80px;
    width: 80px;
    line-height: 80px;
    display: inline-block;
    border-radius: 50%;
    margin-bottom: 20px;
}

.landing .bg-image-feature {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.09;
}

/* ----- Domain Area Css ----- */

.landing .hosting-search-area {
    position: relative;
    z-index: 1;
    background: #fff;
    padding: 50px 30px;
    margin-top: -200px;
    border-radius: 22px;
    -webkit-box-shadow: 0 10px 50px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 50px 0px rgba(0, 0, 0, 0.1);
}

.landing .hosting-search-area .search-form .form-group {
    position: relative;
    margin-bottom: 51px;
}

.landing .hosting-search-area .search-form .form-group input[type="text"] {
    position: relative;
    width: 100%;
    height: 80px;
    background: #fff;
    border: 1px solid #fff;
    padding: 20px;
    border-radius: 5px;
    -webkit-box-shadow: 0 0px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0px 20px 0px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.landing .hosting-search-area .search-form .form-group button {
    position: absolute;
    top: 0;
    overflow: hidden;
    right: 0;
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    background: #4527a4;
    text-align: center;
    padding: 26px 45px;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
    z-index: 1;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    border: none;
}

.landing .hosting-search-area .search-form .form-group button:hover {
    background-color: #4527a4;
}

.landing .hosting-search-area .domain-name {
    position: relative;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

.landing .hosting-search-area .domain-name li {
    position: relative;
    float: left;
    text-align: center;
    width: 20%;
}

.landing .hosting-search-area .domain-name li a {
    position: relative;
    display: inline-block;
    font-size: 18px;
    color: #777;
}

.landing .hosting-search-area .domain-name li:first-child a span {
    color: #4527a4;
}

.landing .hosting-search-area .domain-name li:nth-child(2) a span {
    color: #ffb600;
}

.landing .hosting-search-area .domain-name li a span {
    font-size: 24px;
    font-weight: 700;
    color: #222;
}

.landing .hosting-search-area .domain-name li:nth-child(4) a span {
    color: #ff0000;
}

.landing .hosting-search-area .domain-name li:last-child a span {
    color: #54fe00;
}

.landing .hosting-search-area h2 {
    font-size: 30px;
    margin-bottom: 50px;
    text-align: center;
}

.landing .hosting-feature-content-text h4 {
    font-size: 22px;
}


/* :: Feature Area CSS */

.landing .feature-area {
    position: relative;
    z-index: 1;
}

.landing .single-item-hosting {
    -webkit-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.12);
    background-color: #fff;
    padding: 50px 30px;
    border-radius: 10px;
    margin-bottom: 50px;
    position: relative;
    z-index: 1;
    -webkit-transition-duration: 700ms;
    -o-transition-duration: 700ms;
    transition-duration: 700ms;
}

.landing .single-item-hosting::after {
    position: absolute;
    content: "";

    background-color: #4527a4;
    bottom: 0;
    left: 0;
    height: 5px;
    width: 100%;
    border-radius: 0 0 10px 10px;
}

.landing .single-item-hosting:hover {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
}

.landing .single-hosting-text {
    margin-top: 20px;
}

.landing .single-item-hosting .icon-box {
    color: #4527a4;
}

.landing .single-hosting-text a {
    font-size: 14px;
    color: #696687;
}

.landing .single-hosting-text a i {
    font-size: 11px;
}

.landing .single-hosting-text a:hover {
    color: #4527a4;
}

.landing .single-feature-text-area {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    padding: 20px;
    -webkit-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.12);
    position: relative;
    z-index: 1;
}

.landing .single-feature-text-area::before {
    -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
}

.landing .single-feature-text-area .icon {
    font-size: 50px;
    color: #4527a4;
    margin-right: 15px;
}

.landing .feature-text h4 {
    font-size: 18px;
}

.landing .more-features-thumb .thumb-one {
    position: relative;
    bottom: 65px;
}

.landing .more-features-thumb .thumb-two {
    position: relative;
    top: 40px;
    margin-left: 40px;
}

.landing .more-feature-text h2 {
    font-size: 34px;
}

.landing .bx-tada {
    -webkit-animation: tada 1.5s ease infinite;
    animation: tada 1.5s ease infinite;
}

.landing .single-more-feature-area {
    -webkit-box-shadow: 0px 3px 10px 0px rgba(38, 59, 94, 0.15);
    box-shadow: 0px 3px 10px 0px rgba(38, 59, 94, 0.15);
    padding: 15px;
}

.landing .single-more-feature .icon {
    color: #4527a4;
    font-size: 26px;
    margin-bottom: 10px;
}

.landing .single-more-feature .feature-text h6 {
    font-size: 16px;
}

.landing .text-primary-cu {
    color: #4527a4;
}

/* ----- Home - 3 ----- */

.landing .single-feature-text-area.home-3 {
    -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
}

.landing .content-box .inner-box .single-item {
    position: relative;
    display: block;
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 30px;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(38, 59, 94, 0.1);
    box-shadow: 0px 3px 10px 0px rgba(38, 59, 94, 0.1);
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.landing .content-box .inner-box .single-item .icon-box {
    position: relative;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    color: #4527a4;
    margin-right: 30px;
}

.landing .badge-warning-soft {
    background-color: rgba(235, 47, 6, 0.15);
    color: #eb2f06;
    font-weight: 500;
}

.landing .badge-primary-soft {
    background-color: rgba(52, 46, 173, 0.15);
    color: #4527a4;
    font-weight: 500;
}

.landing .badge-success-soft {
    background-color: rgba(40, 167, 69, 0.15);
    color: #28a745;
    font-weight: 500;
}

.landing .badge.custom {
    position: absolute;
    top: 15px;
    z-index: 1;
    right: 15px;
}

.landing .badge {
    display: inline-block;
    padding: 5px 15px;
    font-weight: 500;
    font-size: 14px;
}

.landing .content-box .inner-box .single-item h5 {
    position: relative;
    display: block;
    font-size: 19px;
    color: #161c2d;
    font-weight: 500;
    margin-bottom: 5px;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.landing .content-box .inner-box .single-item h5 a {
    position: relative;
    display: inline-block;
    color: #161c2d;
    font-weight: 500;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    margin-bottom: 10px;
}

.landing .content-box .inner-box .single-item h5 a:hover {
    color: #4527a4;
}

.landing .single-item p {
    font-size: 15px;
}

.landing .content-box .inner-box .single-item .text {
    position: relative;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}


/* :: Why We Area CSS */

.landing .check-list > li {
    list-style: disc;
}

.landing .about-content h2 {
    font-size: 28px;
}

.landing .about-content h6 {
    color: #4527a4;
    text-transform: uppercase;
}

.landing .about-thumb-3 {
    position: relative;
    z-index: 1;
}

.landing .about-thumb-3 img {
    border-radius: 10px 100px 10px 100px;
    -webkit-box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.04);
}

.landing .about-shape-3 {
    position: absolute;
    width: 600px;
    left: 0px;
    top: -40px;
    background-repeat: no-repeat;
    -webkit-animation: zoom-fade 15s infinite linear;
    animation: zoom-fade 15s infinite linear;
}

.landing .about-area-content-3 {
    padding-left: 50px;
}

.landing .about-area-content-3 h2 {
    font-size: 44px;
}

.landing .about-area-content-3 p {
    font-size: 18px;
}

.landing .single-we-content {
    -webkit-box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.04);
    padding: 30px;
    margin-top: 40px;
}

.landing .why-area-content p {
    font-size: 16px;
}

.landing .single-we-content .icon-box {
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    color: #fff;
    font-size: 18px;
    margin-bottom: 15px;
    border-radius: 2px;
}

.landing .single-we-content-text p {
    font-size: 15px;
    margin-bottom: 0;
}

.landing .icon-box.four {
    background-color: #04d39f;
}

.landing .icon-box.two {
    background-color: #ff054b;
}

/* :: Video Area CSS */

.landing .video-thumb {
    position: relative;
    z-index: 1;
}

.landing .video-icon a i {
    color: #eb2f06;
    font-size: 26px;
}

.landing .video-icon a spna {
    font-size: 20px;
}

.landing .p-15px-t {
    padding-top: 15px;
}

.landing .video-icon.home-4 {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 9;
    margin-top: 0;
}

.landing .video-icon.home-4 a i {
    background-color: #4527a4;
    color: #fff;
    height: 80px;
    width: 80px;
    line-height: 80px;
    text-align: center;
    border-radius: 50%;
}

/* ---- Tab ---- */

.landing .tab-single-card-content {
    background-color: #4527a4;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.10);
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.10);
}

.landing .tab-single-card-content h6 {
    font-size: 18px;
    color: #fff;
}

.landing .tab-single-card-content p {
    color: rgba(255, 255, 255, 0.85);
    margin-bottom: 0;
    font-size: 13px;
}

.landing .tab-style-2 .nav {
    margin-bottom: 25px;
    border: none;
    background-color: #4527a4;
    padding: 13px 10px;
    border-radius: 3px;
    -webkit-box-shadow: 0px 10px 80px -10px rgba(44, 54, 92, 0.30);
    box-shadow: 0px 10px 80px -10px rgba(44, 54, 92, 0.30);
}

.landing .tab-style-2 .nav .nav-item {
    margin: auto;
}

.landing .tab-style-2 .nav .nav-item a.active {
    color: #fff;
}

.landing .tab-style-2 .nav .nav-item a {
    padding: 8px 5px;
    font-weight: 500;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.86);
    position: relative;
    display: block;
    text-align: center;
}

.landing #pills-tabContent p {
    font-size: 14px;
}

.landing .tab-style-2 .nav .nav-item a.active::after {
    width: 100%;
}

.landing .tab-style-2 .nav .nav-item a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0px;
    -o-transition: ease-in-out all 0.55s;
    -webkit-transition: ease-in-out all 0.55s;
    transition: ease-in-out all 0.55s;
    height: 2px;
    background: #eb2f06;
}

.landing .video-us-text h4 {
    font-size: 34px;
    text-transform: capitalize;
    margin-bottom: 30px;
}

.landing .video-us-text h4 span {
    color: #4527a4;
}

/* :: Single Feature Area CSS */

.landing .single-feature-area {
    -webkit-transition-duration: 1000ms;
    -o-transition-duration: 1000ms;
    transition-duration: 1000ms;
    margin-bottom: 50px;
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding: 60px 30px;
    -webkit-box-shadow: 0 5px 30px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0 5px 30px 0px rgba(0, 0, 0, 0.12);
    border-radius: 30px;
    overflow: hidden;
}

.landing .hosting-single-feature-area {
    background-color: #fff;
    padding: 70px 30px 60px 30px;
    border: 2px dashed #ccc;
    position: relative;
    z-index: 1;
    margin-bottom: 100px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.landing .hosting-single-feature-area:hover {
    -webkit-box-shadow: 0 18px 56px rgba(47, 91, 234, 0.135);
    box-shadow: 0 18px 56px rgba(47, 91, 234, 0.135);
    border-color: transparent;
}

.landing .hosting-single-feature-area .feature-icon {

    background-color: #4527a4;
    height: 80px;
    width: 80px;
    line-height: 80px;
    text-align: center;
    color: #fff;
    font-size: 36px;
    position: absolute;
    top: -40px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 50%;
}

.landing .hosting-single-feature-area:hover .feature-icon i {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}


.landing .single-feature-area .feature-icon {
    margin-bottom: 30px;
    font-size: 50px;
    line-height: 50px;
    color: #4527a4;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.landing .feature-content-text a {
    color: #696687;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.landing .feature-content-text a:hover {
    color: #4527a4;
}

.landing .feature-content-text a i {
    font-size: 12px;
}

.landing .feature-content-text h4 {
    font-size: 18px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    position: relative;
    z-index: 2;
    margin-bottom: 10px;
}

.landing .feature-content-text h4::after {
    position: absolute;
    z-index: -1;
    content: "";

    background-color: #4527a4;
    height: 40px;
    width: 100%;
    left: -500px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.landing .feature-content-text p {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.landing .single-feature-area:hover .feature-content-text h4::after {
    left: -30px;
}

.landing .single-feature-area:hover .feature-content-text h4 {
    color: #fff;
}

/* :: Call to action Area CSS */

.landing .call-to-action-area {
    padding: 70px 40px;
    background-color: #4527a4;
    position: relative;
    border-radius: 5px;
    -webkit-box-shadow: 0 8px 60px 0 rgba(103, 151, 255, .30), 0 12px 90px 0 rgba(103, 151, 255, .30);
    box-shadow: 0 8px 60px 0 rgba(103, 151, 255, .30), 0 12px 90px 0 rgba(103, 151, 255, .30);
}

.landing .contact-bg-pattern {
    background-color: #fff;
    padding: 0 30px;
}

@media (min-width: 320px) and (max-width: 991px) {
    .landing .ca-mb-50 {
        margin-bottom: 50px;
    }
}

.landing .call-to-title h2 {
    text-transform: uppercase;
}

.landing .single-call-desc-content h4 {
    color: #fff;
    font-size: 22px;
    font-weight: 500;
}

.landing .single-call-desc-content p {
    color: rgba(255, 255, 255, .95);
    margin-bottom: 0;
}

.landing .call-to-action-area::before {
    position: absolute;
    content: "";
    z-index: -1;
    top: -20px;
    left: -20px;
    border: 6px solid#4527a4;
    height: 40px;
    width: 40px;
    border-radius: 5px;
    -webkit-animation: tada 1s ease infinite;
    animation: tada 1s ease infinite;

}

@media (min-width: 320px) and (max-width: 767px) {
    .landing .call-to-action-area::after {
        display: none;
    }

    .landing .call-to-action-area::before {
        display: none;
    }
}


@-webkit-keyframes loadingD {
    to {
        -webkit-transform: translate();
        transform: translate()
    }

    from {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }

}


@keyframes loadingD {
    to {
        -webkit-transform: translate();
        transform: translate()
    }

    from {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }

}


@keyframes loadingD {
    to {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    from {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.landing .form-control.started {
    height: 50px;
}

.landing .form-control.started {
    height: 60px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
}

.landing .btn.welcome-btn.started {
    height: 60px;
    background-color: #4527a4;
    border-radius: 5px;
}

.landing .btn.welcome-btn.started:hover {
    background-color: #4527a4;
    color: #fff;
}

/*  ---- Home - 2 ---- */
.landing .call-to-text-2 h2 {
    font-size: 50px;
    color: #fff;
}

.landing .call-to-text-2 p {
    font-size: 16px;
    color: #fff;
}

/* :: Set up Area CSS */

.landing .set-up-content-text h6 {
    font-size: 16px;
    color: #4527a4;
    text-transform: uppercase;
}

.landing .set-up-content-text h4 {
    font-size: 32px;
    margin-bottom: 15px;
}

.landing .set-up-content-text p {
    margin-bottom: 20px;
}

.landing .set-up-content-list li {
    margin-bottom: 20px;
    font-size: 14px;
}

@media (min-width: 320px) and (max-width: 575px) {
    .landing .set-up-content-list li {
        font-size: 13px;
    }
}

.landing .set-up-content-list li i {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: #eb2f06;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    font-size: 11px;
    color: #fff;
    margin-right: 10px;
}

.landing .set-up-thumb-home-2 {
    top: 34%;
    left: -16%;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-name: bounce_fountainG;
    animation-name: bounce_fountainG;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: normal;
    animation-direction: normal;
}

@keyframes bounce_fountainG {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(25px);
        transform: translateY(25px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

/* :: Price Table Area CSS */

.landing .price-table-area {
    position: relative;
    z-index: 1;
}

.landing .single-price-table-area {
    -webkit-box-shadow: 0 17px 43px rgba(47, 91, 234, 0.135);
    box-shadow: 0 17px 43px rgba(47, 91, 234, 0.135);
    background-color: #fff;
    border-radius: 5px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    margin-bottom: 50px;
    border: 2px dashed #ebebeb;
}

.landing .pricing-icon {
    margin-bottom: 20px;
}

.landing .pattern-image-price {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 60px;
}

.landing .pricing-icon i {
    font-size: 40px;
    height: 80px;
    width: 80px;
    display: inline-block;
    line-height: 80px;

    background-color: #4527a4;
    border-radius: 50%;
    text-align: center;
    color: #fff;
}

.landing .price-table-heading {
    padding: 30px;
    border-bottom: 2px dashed #ebebeb;
}

.landing .price-table-heading h6 {
    font-size: 22px;
    margin-bottom: 30px;
    color: #4527a4;
    text-transform: uppercase;
}

.landing .price-table-heading h2 {
    margin-bottom: 10px;
    font-size: 32px;
    line-height: 1;
}

.landing .price-table-heading h2 span {
    font-size: 15px;
    font-weight: 500;
    color: #777;
}

.landing .price-table-body {
    padding: 40px 50px;
}

.landing .price-table-body ul li {
    margin-bottom: 25px;
}

.landing .price-table-body ul li span {
    position: relative;
    z-index: 1;
}

/** pricing-style-two **/

.landing .pricing-style-two {
    position: relative;
    background: #f8f6ff;
    padding: 115px 0px 120px 0px;
}

.landing .pricing-table-new {
    border: 1px solid #d7d7d7;
    -webkit-transition-duration: 700ms;
    -o-transition-duration: 700ms;
    transition-duration: 700ms;
    position: relative;
}

.landing .pricing-style-two .sec-title {
    margin-bottom: 110px;
}

.landing .pricing-table-new .pricing-table {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 41px 0 45px 0;
    border-radius: 30px;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.landing .pricing-table-new .pricing-table .choice-box {
    position: absolute;
    width: 100%;
    left: -115px;
    top: 40px;
    background: #4527a4;
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    padding: 10px 30px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    opacity: 0;
}

.landing .pricing-table-new .pricing-table .table-header .title {
    position: relative;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 30px;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    text-transform: uppercase;
}

.landing .pricing-table-new .pricing-table .table-header .text {
    position: relative;
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.landing .pricing-table-new .pricing-table .table-header .price {
    position: relative;
    display: block;
    font-size: 44px;
    font-weight: 600;
    margin-bottom: 10px;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    color: #4527a4;
    border-bottom: 1px solid #ebebeb;
    border-top: 1px solid #ebebeb;
    padding-top: 20px;
    padding-bottom: 20px;
}

.landing .pricing-table-new .pricing-table .table-header .price span {
    font-size: 14px;
    font-weight: 400;
}

.landing .pricing-table-new .pricing-table .table-content {
    position: relative;
    display: block;
    padding: 20px 0px 20px 0px;
}

.landing .pricing-table-new .pricing-table .table-content li {
    position: relative;
    display: block;
    font-size: 15px;
    margin-bottom: 20px;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.landing .pricing-table-new .pricing-table .table-content li i {
    color: #4527a4;
    margin-right: 3px;
    font-size: 14px;
}

.landing .pricing-table-new .pricing-table .table-content li:last-child {
    margin-bottom: 0px;
}

.landing .pricing-table-new .pricing-table .table-footer a {
    position: relative;
    display: inline-block;
    background: #4527a4;
    font-size: 16px;
    text-align: center;
    padding: 10px 40px;
    border-radius: 30px;
}

.landing .pricing-table-new:hover .pricing-table .table-footer a {
    background: #4527a4;
}

.landing .subscribe-section.home-3 {
    padding-top: 115px;
}

.landing .pricing-table-new:hover .pricing-table .table-header .price {
    background-color: #4527a4;
    color: #fff;
}

/* :: Feature Area CSS */

.landing .features-slider-wrapper {
    padding: 67px 23px 90px 26px;
    background-size: 100%;
    max-width: 350px;
    margin: 0 auto;
}

.landing .feature-icon img {
    width: 70px;
}

/* ----- Login Css Area ----- */

.landing .main-content- {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* :: Single Blog Main Page Area CSS */

.landing .single-blog-area {
    background-color: #fff;
}

.landing .blog-author .author-thumb {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40px;
    flex: 0 0 40px;
    max-width: 40px;
    width: 40px;
    margin-right: 10px;
}

.landing .blog-author h6 {
    margin-bottom: 0;
    font-size: 14px;
    text-transform: capitalize;
}

.landing .post-meta a {
    color: #4b5264;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    font-size: 15px;
    font-weight: 500;
    margin-right: 10px;
}

.landing .post-meta a i {
    font-size: 11px;
    margin-right: 3px;
    line-height: 11px;
}

.landing .post-meta a:hover {
    color: #4527a4;
}

.landing .single-blog-content {
    padding: 20px;
}

.landing .single-blog-content span {
    font-size: 13px;
    color: #777;
    margin-bottom: 10px;
    display: block;
    text-transform: uppercase;
    font-weight: 500;
}

.landing .single-blog-content a h5 {
    font-size: 18px;
    -webkit-transition-duration: 400ms;
    -o-transition-duration: 400ms;
    transition-duration: 400ms;
    margin-bottom: 15px;
}

.landing .single-blog-content p {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.landing .blog-title a {
    color: #666;
    margin-bottom: 15px;
    display: block;
    font-size: 14px;
}

.landing .blog-title a i {
    margin-right: 3px;
    font-size: 12px;
}

.landing .blog-title a:hover {
    color: #4527a4;
}

.landing .single-blog-content a h5:hover {
    color: #4527a4;
}

.landing .comments-area h5 {
    margin-bottom: 5px;
}

.landing .comments-area h5 a {
    font-size: 16px;
    color: #212529;
    font-weight: 500;
}

.landing .comment-form h4 {
    font-size: 22px;
    margin-bottom: 30px;
}

.landing .single--content p {
    font-size: 15px;
}

.landing .blog-btn a {
    height: 30px;
    min-width: 100px;
    display: inline-block;
    line-height: 30px;
    text-align: center;
    color: #fff;
    font-size: 13px;
    background-color: #4527a4;
    border-radius: 3px;
    -webkit-transition-duration: 400ms;
    -o-transition-duration: 400ms;
    transition-duration: 400ms;
    text-transform: capitalize;
}

.landing .blog-btn a:hover {
    background-color: #2f23fd;
}

.landing .blog-thumb {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.landing .blog-thumb img {
    -webkit-transition-duration: 700ms;
    -o-transition-duration: 700ms;
    transition-duration: 700ms;
}

.landing .single-blog-area:hover .blog-thumb img {
    -webkit-transform: scale(1.4);
    -ms-transform: scale(1.4);
    transform: scale(1.4);
}

.landing .blog-badge a {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 22;
    background-color: #4527a4;
    height: 25px;
    width: 65px;
    line-height: 25px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    font-weight: 500;
    border-radius: 3px;
}

/* :: Counter up Area CSS */
.landing .single-counter-2 {
    text-align: center;
}

.landing .counter-number-2 h3 {
    font-size: 40px;
    letter-spacing: 1px;
    font-weight: 500;
}

.landing .counter-number-2 p {
    font-size: 18px;
    margin-bottom: 0;
}

/* ----- Download Area ----- */

.landing .download-app-area {
    position: relative;
    z-index: 1;
}

.landing .download-content h4 {
    font-size: 40px;
    color: #fff;
}

.landing .download-content p {
    font-size: 18px;
    color: #fff;
}

.landing .download-content-3 h2 {
    font-size: 40px;
}

.landing .download-content-3 p {
    font-size: 18px;
}

.landing .bg-shape-two {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    z-index: -1;
}

.landing .download-content h6 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 0;
}

/* ---- Team Member Area Css ---- */

.landing .team-style-two {
    position: relative;
    display: block;
    padding: 150px 0px 100px 0px;
}

.landing .single-team-card {
    position: relative;
    display: block;
    margin-bottom: 50px;
    text-align: center;
}

.landing .single-team-card .image-box {
    position: relative;
    display: block;
    background-color: #4527a4;
    overflow: hidden;
    border-radius: 50%;
}

.landing .single-team-card .image-box img {
    width: 100%;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    border-radius: 50%;
}

.landing .single-team-card:hover .image-box img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0.5;
}

.landing .single-team-card .image-box:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #4527a4;
    opacity: 0.80;
    content: '';
    -webkit-transition: -webkit-transform 0.6s;
    transition: -webkit-transform 0.6s;
    -o-transition: transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
}

.landing .single-team-card:hover .image-box:before {
    -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
}

.landing .single-team-card .content-box {
    position: relative;
    display: block;
}

.landing .single-team-card .content-box span {
    font-size: 14px;
    margin-bottom: 15px;
    display: block;
}

.landing .single-team-card .content-box h4 {
    position: relative;
    display: block;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}

.landing .single-team-card .content-box h4 a {
    color: #212529;
}

.landing .single-team-card .content-box h4 a:hover {
    color: #4527a4;
}

.landing .single-team-card .content-box .text {
    position: relative;
    display: block;
    margin-bottom: 13px;
}

.landing .single-team-card .content-box .team-social li {
    position: relative;
    display: inline-block;
    margin-right: 10px;
}

.landing .single-team-card .content-box .team-social li:last-child {
    margin-right: 0px;
}

.landing .single-team-card .content-box .team-social li a {
    position: relative;
    display: inline-block;
    font-size: 14px;
    color: #34263c;
}

.landing .single-team-card .content-box .team-social li a:hover {
    color: #4527a4;
}


/* :: App shot Area CSS */

.landing .slick-initialized .slick-slide {
    display: block;
    margin: 15px;
}

.landing .slick-arrow {
    background-color: #4527a4;
    border: none;
    color: #fff;
    font-size: 14px;
    padding: 5px 10px;
    letter-spacing: 1px;
    margin-left: 15px;
    border-radius: 5px 12px 0 12px;
    cursor: pointer;
}

/* :: Contact Action Area CSS */

.landing .contact-action-text h2 {
    color: #fff;
    font-size: 28px;
    font-weight: 500;
}

.landing .contact-action-button a {
    border: 1px solid #fff;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.landing .contact-action-button a:hover {
    background-color: #4527a4;
    border-color: #4527a4;
}

@media (min-width: 320px) and (max-width: 767px) {
    .landing .mt-sm-cu {
        margin-top: 50px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .landing .mt-sm-cu-30 {
        margin-top: 30px;
    }
}

/* ----- Footer Contact CSS ----- */

.landing .footer-contact-area {
    position: relative;
    z-index: 1;
}

.landing .bg-pattern-image-3 {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0.4;
}

.landing .footer-logo a img {
    max-width: 70px;
}

.landing .gmaps {
    height: 450px;
    width: 100%;
    border: 0;
}

.landing .dropdown-menu .dropdown-item {
    color: #222;
}

.landing #dropdownMenuLink {
    padding: 0;
}
.landing #dropdownMenuLink2 {
    padding: 0;
}

.landing .dropdown-menu .dropdown-item:hover {
    color: #4527a4;
}

.landing .footer-single-widget h4 {
    font-size: 20px;
    color: #fff;
    margin-bottom: 20px;
}

.landing .footer-single-widget ul li {
    display: block;
    margin-bottom: 12px;
}

.landing .footer-single-widget ul li:last-child {
    margin-bottom: 0;
}

.landing .footer-single-widget ul li a {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.90);
    position: relative;
    -webkit-transition-duration: 400ms;
    -o-transition-duration: 400ms;
    transition-duration: 400ms;
    font-weight: 400;
}

.landing .footer-single-widget ul li a i {
    margin-right: 5px;
}

.landing .footer-single-widget ul li a:hover {
    color: #fff;
}

.landing .footer-single-widget p {
    color: rgba(255, 255, 255, 0.90);
    margin-bottom: 0;
}

.landing .copy-right-area {
    padding: 20px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.landing .copy-right-menu ul li {
    display: inline-block;
}

.landing .copy-right-menu ul li a {
    color: rgba(255, 255, 255, 0.95);
    margin-right: 15px;
    font-size: 15px;
    font-weight: 400;
}

.landing .footer-single-widget ul li a::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    left: auto;
    width: 0px;
    height: 1px;
    -o-transition: ease all 0.35s;
    -webkit-transition: ease all 0.35s;
    transition: ease all 0.35s;
    background: currentColor;
}

.landing .footer-single-widget ul li a:hover::after {
    width: 100%;
    left: 0;
    right: auto;
}

.landing .copy-right-content p {
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.95);
    font-size: 15px;
}

.landing .footer-contact-icon {
    margin-top: 20px;
}

.landing .footer-contact-icon a {
    display: inline-block;
    color: #fff;
    font-size: 16px;
    text-align: center;
    margin-right: 10px;
    -webkit-transition-duration: 400ms;
    -o-transition-duration: 400ms;
    transition-duration: 400ms;
}

.landing .footer-contact-icon a:hover {
    color: #eb2f06;
}

/* ----- Client Area CSS ----- */

.landing .client-feedback-area-3 {
    position: relative;
    z-index: 1;
}

.landing .client-thumb {
    position: relative;
    z-index: 1;
}

.landing .client-thumb::after {
    position: absolute;
    content: "";
    background-color: #4527a4;
    height: 300px;
    width: 300px;
    top: 8%;
    left: 12%;
    z-index: -1;
    border-radius: 63px 123px 80px 96px;
    -webkit-animation: loadingD 45s cubic-bezier(.0, .0, .0, .0) infinite;
    animation: loadingD 45s cubic-bezier(.0, .0, .0, .0) infinite;
}

.landing .client-desc {
    border: 2px dashed #ebebeb;
    padding: 30px;
    border-radius: 3px;
}

@media (min-width: 320px) and (max-width: 575px) {
    .landing .client-mt-50 {
        margin-top: 50px;
    }
}


@media (min-width: 320px) and (max-width: 575px) {
    .landing .client-thumb::after {
        display: none;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .landing .client-thumb::after {
        top: 10%;
        left: 33%;
        height: 200px;
        width: 200px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .landing .client-thumb::after {
        top: 8%;
        left: 24%;
        height: 170px;
        width: 170px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .landing .client-thumb::after {
        top: 8%;
        left: 24%;
        height: 125px;
        width: 119px;
    }
}

@keyframes loadingD {
    to {
        -webkit-transform: translate();
        transform: translate()
    }

    from {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }

}

@keyframes loadingD {
    to {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    from {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.landing .client-thumb img {
    border-radius: 50%;
}

.landing .client-desc p {
    color: #677294;
    font-size: 16px;
    line-height: 2;
}

.landing .client-desc h4 {
    font-size: 20px;
    margin-bottom: 0;
}

@media (min-width: 320px) and (max-width: 575px) {
    .landing .client-desc h4 {
        font-size: 16px;
    }
}

.landing .client-desc span {
    font-weight: 300;
}

.landing .client-feedback-area .owl-nav {
    position: absolute;
    bottom: 0;
    right: 0;
}

.landing .client-feedback-area .owl-nav .owl-prev,
.landing .client-feedback-area .owl-nav .owl-next {
    display: inline-block;
    background-color: #4527a4;
    margin: 0 5px;
    height: 35px;
    width: 40px;
    font-size: 14px;
    line-height: 36px;
    text-align: center;
    color: #fff;
    border-radius: 3px;
}

.landing .rating-area ul li {
    display: inline-block;
    font-size: 14px;
    margin-right: 5px;
    color: #ff922b;
}

.landing .client-rating ul li {
    display: inline-block;
    font-size: 12px;
    margin-right: 3px;
    color: #ff922b;
}

.landing .client-thumb-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60px;
    flex: 0 0 60px;
    width: 60px;
    max-width: 60px;
    margin-right: 20px;
}

.landing .single-client-3 p {
    margin-bottom: 0;
}

.landing .client-info h5 {
    font-size: 15px;
    margin-bottom: 2px;
}
.landing .client-info-img {
    width:7em;
    height:auto;
}

.landing .client-info h6 {
    font-size: 12px;
    color: #696687;
}

.landing .clint-thumb-text {
    margin-top: 30px;
}

.landing .client-thumb-3 img {
    border-radius: 50%;
}

.landing .single-client-3 {
    padding: 30px;
    position: relative;
    background-color: #fff;
    border-radius: 0.25rem;
    z-index: 2;
    -webkit-box-shadow: 0px 0px 25px #00000017;
    box-shadow: 0px 0px 25px #00000017;
    margin: 15px;
}

.landing .client-rating {
    margin-top: 30px;
}

.landing .single-clinet-info {
    position: relative;
    z-index: 9;
}

.landing .rating-area {
    font-size: 100px;
    line-height: 100px;
    position: absolute;
    bottom: 30px;
    right: 50px;
    z-index: 0;
    color: rgba(0, 0, 0, 0.05);
}

.landing .client-feedback-area-3 .owl-nav {
    text-align: center;
}

.landing .client-feedback-area-3 .owl-nav .owl-prev,
.landing .client-feedback-area-3 .owl-nav .owl-next {
    color: #fff;
    font-size: 16px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    display: inline-block;
    margin: 0 5px;
    position: absolute;
    top: -50px;
    right: 15px;
    background-color: #22105f;
    padding: 8px 20px;
    border-radius: 3px;
    -webkit-box-shadow: 0px 0px 25px #00000017;
    box-shadow: 0px 0px 25px #00000017;
}

.landing .client-feedback-area-3 .owl-nav .owl-prev {
    right: 75px;
}

.landing .client-feedback-area-3 .owl-nav .owl-prev:hover,
.landing .client-feedback-area-3 .owl-nav .owl-next:hover {
    background-color: #4527a4;
    color: #fff;
}

.landing .client-feedback-area .owl-nav .owl-prev:hover,
.landing .client-feedback-area .owl-nav .owl-next:hover {
    background-color: #22105f;
    color: #fff;
}


/* ------ Wee ofer area css ----- */

.landing .we-offer-content h3 {
    font-size: 42px;
    display: block;
    margin-bottom: 20px;
}

.landing .we-offer-content span {
    font-weight: 400;
    margin-bottom: 20px;
    font-size: 18px;
    color: #212529;
    display: block;
}

.landing .we-offer-content p {
    font-size: 16px;
}

.landing .we-offer-content ul li {
    margin-bottom: 15px;
    color: #212529;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.landing .we-offer-content ul li span {
    font-size: 16px;
}

.landing .we-offer-content ul li span {
    margin-bottom: 0;
}

.landing .we-offer-content ul li i {
    color: #4527a4;
    margin-right: 8px;
    font-size: 22px;
    line-height: 22px;

}

.landing .manage-thumb {
    padding-right: 50px;
}

.landing .manage-content h3 {
    font-size: 44px;
}

/* ----- Client - 2 ----- */

.landing .single-client-2 {
    background-color: #fff;
    border-radius: 5px;
    padding: 30px;
}

.landing .client-thumb-2 {
    margin-right: 30px;
}

.landing .client-thumb-2 img {
    border-radius: 3px;
}

.landing .client-desc-2 p {
    font-size: 20px;
    margin-top: 20px;
}

.landing .client-desc-2 h5 {
    font-size: 18px;
    margin-bottom: 5px;
}

.landing .client-desc-2 h6 {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #4527a4;
    font-weight: 500;
}

.landing .client-feedback-area-2 .owl-dots {
    text-align: center;
}


.landing .client-feedback-area-2 .owl-dots .owl-dot {
    height: 10px;
    width: 10px;
    display: inline-block;
    background-color: #4527a4;
    margin: 0 5px;
    border-radius: 50%;
}

.landing .client-feedback-area-2 .owl-dots .owl-dot.active {
    background-color: #eb2f06;
}

/* ----- Contact Support Area CSS ----- */

.landing .single-contact-info .icon {
    height: 40px;
    width: 40px;
    color: #234262;
    background-color: #e1e3f7;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    font-size: 15px;
    margin-right: 15px;
    -webkit-box-shadow: 0 0 20px 15px rgba(255, 255, 255, 0.20);
    box-shadow: 0 0 20px 15px rgba(255, 255, 255, 0.20);
}

.landing .contact-info h5 {
    font-size: 16px;
}

.landing .form-control {
    position: relative;
    width: 100%;
    height: 50px;
    background: #fff;
    padding: 10px 60px 10px 30px;
    font-size: 13px;
    font-weight: 400;
    border: 1px solid #eaeaea;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    border-radius: 5px;
}

.landing .form-text-area {
    position: relative;
    width: 100%;
    background: #fff;
    padding: 10px 60px 10px 30px;
    font-size: 13px;
    font-weight: 400;
    border: 1px solid #eaeaea;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    border-radius: 5px;
}

.landing .form-text-area:focus,
.landing .form-control:focus {
    background-color: #fff;
    border-color: #4527a4;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 5px;
}


.landing .single-card-contact .icon-area {
    font-size: 40px;
    line-height: 40px;
    color: #4527a4;
    margin-right: 15px;
}

.landing .single-card-contact {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 20px;
}

.landing .single-card-contact:nth-child(2) {
    padding-top: 20px;
}

.landing .single-card-contact:last-child {
    border-bottom: none;
    padding-top: 20px;
}

.landing .card-content-text h5 {
    margin-bottom: 8px;
}

.landing .card-content-text p {
    margin-bottom: 8px;
}

/* ----- Login Area ----- */

.landing .login-bg-thumb {
    padding: 50px 0;
    border-radius: 15px 15px 0 0;
}

.landing .login-bg-thumb h3 {
    font-size: 40px;
    color: #fff;
}

.landing .middle-box {
    border-radius: 15px;
}

.landing .middle-box .card {
    border-radius: 15px;
}

.landing .forget-title a {
    color: #22105f;
}

.landing .forget-title a:hover {
    color: #4527a4;
}

/* ----- Frequently asked question Area CSS ----- */

.landing .single_faq {
    padding: 30px;
    background-color: #fff;
    border-left: 2px solid#4527a4;
    -webkit-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.12);
}

.landing .faq-thumb {
    padding-left: 50px;
}

.landing .single_faq h5 {
    font-size: 20px;
}

@media (min-width: 320px) and (max-width: 575px) {
    .landing .single_faq h5 {
        font-size: 18px;
    }
}

.landing .single_faq p {
    margin-bottom: 0px;
}

.landing .support_text h4 {
    font-size: 16px;
    margin-bottom: 0;
}

.landing .support_text h4 span {
    font-weight: 500;
    color: #4527a4;
}


/* ---- Breadcrumb area ---- */

.landing .breadcrumb {
    margin-bottom: 0;
    position: relative;
    z-index: 1;
}

.landing .bread-shape-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.6;
    -webkit-animation: zoom-fade 5s infinite linear;
    animation: zoom-fade 5s infinite linear;
}

.landing .breadcrumb.breadcrumb_bg {
    height: 350px;
    border-radius: 0;
}

.landing .breadcrumb_iner_item h2 {
    font-size: 36px;
    color: #fff;
    margin-bottom: 15px;
}

.landing .custom-breadcrumb ol li a {
    color: #ffffff;
    opacity: 0.80;
    font-weight: 400;
}

.landing .custom-breadcrumb ol li.breadcrumb-item.active {
    color: #fff;
}

.landing .custom-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    font-family: themify;
    content: "\e649";
    font-size: 10px;
    color: #ffffff;
    opacity: 0.9;
}

.landing .list-inline-item:not(:last-child) {
    margin-right: 0;
}


/* ----- Partner Area css  ----- */

.landing .single-partner-area {
    -webkit-box-shadow: 0 0 2.1875rem 0 rgba(0, 0, 0, 0.03);
    box-shadow: 0 0 2.1875rem 0 rgba(0, 0, 0, 0.03);
}

.landing .partner-content-text h2 {
    font-size: 36px;
}

.landing .partner-content-text span {
    color: #eb2f06;
}

/* --- Blog Details ---- */

.landing .latest-blog-area .area-heading {
    margin-bottom: 70px
}

.landing .single-blog {
    overflow: hidden;
    margin-bottom: 30px
}

.landing .single-blog:hover {
    -webkit-box-shadow: 0px 10px 20px 0px rgba(42, 34, 123, 0.1);
    box-shadow: 0px 10px 20px 0px rgba(42, 34, 123, 0.1)
}

.landing .single-blog .thumb {
    overflow: hidden;
    position: relative
}

.landing .single-blog .thumb:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

.landing .single-blog h4 {
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 34px;
    margin-bottom: 25px
}

.landing .single-blog a {
    font-size: 20px;
    font-weight: 600
}

.landing .single-blog .date {
    color: #666666;
    text-align: left;
    display: inline-block;
    font-size: 13px;
    font-weight: 300
}

.landing .single-blog .tag {
    text-align: left;
    display: inline-block;
    float: left;
    font-size: 13px;
    font-weight: 300;
    margin-right: 22px;
    position: relative
}

.landing .single-blog .tag:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 10px;
    background: #acacac;
    right: -12px;
    top: 7px
}

.landing .blog-thumb img {
    width: 100%;
}

@media (max-width: 1199px) {
    .landing .single-blog .tag {
        margin-right: 8px
    }

    .landing .single-blog .tag:after {
        display: none
    }
}

.landing .single-blog .likes {
    margin-right: 16px
}

@media (max-width: 800px) {
    .landing .single-blog {
        margin-bottom: 30px
    }
}

.landing .single-blog .single-blog-content {
    padding: 30px
}

.landing .single-blog .single-blog-content .meta-bottom p {
    font-size: 13px;
    font-weight: 300
}

.landing .single-blog .single-blog-content .meta-bottom i {
    color: #fdcb9e;
    font-size: 13px;
    margin-right: 7px
}

@media (max-width: 1199px) {
    .landing .single-blog .single-blog-content {
        padding: 15px
    }
}

.landing .single-blog:hover .thumb:after {
    opacity: .7;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

@media (max-width: 1199px) {
    .landing .single-blog h4 {
        -webkit-transition: all 300ms linear 0s;
        -o-transition: all 300ms linear 0s;
        transition: all 300ms linear 0s;
        border-bottom: 1px solid #dfdfdf;
        padding-bottom: 14px;
        margin-bottom: 12px
    }

    .landing .single-blog h4 a {
        font-size: 18px
    }
}

.landing .full_image.single-blog {
    position: relative
}

.landing .full_image.single-blog .single-blog-content {
    position: absolute;
    left: 35px;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

@media (min-width: 992px) {
    .landing .full_image.single-blog .single-blog-content {
        bottom: 100px
    }
}

.landing .full_image.single-blog h4 {
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    border-bottom: none;
    padding-bottom: 5px
}

.landing .full_image.single-blog a {
    font-size: 20px;
    font-weight: 600
}

.landing .full_image.single-blog .date {
    color: #fff
}

.landing .full_image.single-blog:hover .single-blog-content {
    opacity: 1;
    visibility: visible;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

.landing .l_blog_item .l_blog_text .date {
    margin-top: 24px;
    margin-bottom: 15px
}

.landing .l_blog_item .l_blog_text .date a {
    font-size: 12px
}

.landing .l_blog_item .l_blog_text h4 {
    font-size: 18px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 0px;
    padding-bottom: 20px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

.landing .l_blog_item .l_blog_text p {
    margin-bottom: 0px;
    padding-top: 20px
}

.landing .causes_slider .owl-dots {
    text-align: center;
    margin-top: 80px
}

.landing .causes_slider .owl-dots .owl-dot {
    height: 14px;
    width: 14px;
    background: #e9e9e9;
    display: inline-block;
    margin-right: 7px
}

.landing .causes_slider .owl-dots .owl-dot:last-child {
    margin-right: 0px
}

.landing .causes_item {
    background: #fff
}

.landing .causes_item .causes_img {
    position: relative
}

.landing .causes_item .causes_img .c_parcent {
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
    height: 3px;
    background: rgba(255, 255, 255, 0.5)
}

.landing .causes_item .causes_img .c_parcent span {
    width: 70%;
    height: 3px;
    position: absolute;
    left: 0px;
    bottom: 0px
}

.landing .causes_item .causes_img .c_parcent span:before {
    content: "75%";
    position: absolute;
    right: -10px;
    bottom: 0px;
    color: #fff;
    padding: 0px 5px
}

.landing .causes_item .causes_text {
    padding: 30px 35px 40px 30px
}

.landing .causes_item .causes_text h4 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
    cursor: pointer
}

.landing .causes_item .causes_text p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 0px
}

.landing .causes_item .causes_bottom a {
    width: 50%;
    border: 1px solid;
    text-align: center;
    float: left;
    line-height: 50px;
    color: #fff;
    font-size: 14px;
    font-weight: 500
}

.landing .causes_item .causes_bottom a + a {
    border-color: #e9e9e9;
    background: #fff;
    font-size: 14px
}

.landing .latest_blog_area {
    background: #f9f9ff
}

.landing .single-recent-blog-post {
    margin-bottom: 30px
}

.landing .single-recent-blog-post .thumb {
    overflow: hidden
}

.landing .single-recent-blog-post .thumb img {
    -webkit-transition: all 0.7s linear;
    -o-transition: all 0.7s linear;
    transition: all 0.7s linear
}

.landing .single-recent-blog-post .details {
    padding-top: 30px
}

.landing .single-recent-blog-post .details .sec_h4 {
    line-height: 24px;
    padding: 10px 0px 13px;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear
}

.landing .single-recent-blog-post .date {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400
}

.landing .single-recent-blog-post:hover img {
    -webkit-transform: scale(1.23) rotate(10deg);
    -ms-transform: scale(1.23) rotate(10deg);
    transform: scale(1.23) rotate(10deg)
}

.landing .tags .tag_btn {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid #e9e9e9;
    display: inline-block;
    padding: 1px 18px;
    text-align: center
}

.landing .tags .tag_btn + .tag_btn {
    margin-left: 2px
}

.landing .blog_categorie_area {
    padding-top: 30px;
    padding-bottom: 30px
}

@media (min-width: 900px) {
    .landing .blog_categorie_area {
        padding-top: 80px;
        padding-bottom: 80px
    }
}

@media (min-width: 1100px) {
    .landing .blog_categorie_area {
        padding-top: 120px;
        padding-bottom: 120px
    }
}

.landing .categories_post {
    position: relative;
    text-align: center;
    cursor: pointer
}

.landing .categories_post img {
    max-width: 100%
}

.landing .categories_post .categories_details {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: rgba(34, 34, 34, 0.75);
    color: #fff;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.landing .categories_post .categories_details h5 {
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    color: #fff;
    position: relative
}

.landing .categories_post .categories_details p {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 0px
}

.landing .categories_post .categories_details .border_line {
    margin: 10px 0px;
    background: #fff;
    width: 100%;
    height: 1px
}

.landing .categories_post:hover .categories_details {
    background: rgba(222, 99, 32, 0.85)
}

.landing .blog_item {
    margin-bottom: 50px;
}

.landing .blog_item_img {
    overflow: hidden;
}

.landing .blog_item_img img {
    -webkit-transition-duration: 5000ms;
    -o-transition-duration: 5000ms;
    transition-duration: 5000ms;
}

.landing .blog_item:hover .blog_item_img img {
    -webkit-transform: scale(1.7);
    -ms-transform: scale(1.7);
    transform: scale(1.7);
}

.landing .blog_details p {
    margin-bottom: 30px
}

.landing .blog_details h2 {
    font-size: 22px;
    margin-bottom: 8px;
    margin-top: 20px;
    font-weight: 500;
}

.landing .blog-info-link li {
    float: left;
    font-size: 14px
}

.landing .blog-info-link li a {
    color: #4b5264;
    font-weight: 400;
}

.landing .blog-info-link li i,
.landing .blog-info-link li span {
    font-size: 13px;
    margin-right: 5px;
    color: #4b5264;
}

.landing .blog-info-link {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.landing .blog-info-link li::after {
    content: "|";
    padding-left: 10px;
    padding-right: 10px
}

.landing .blog-info-link li:last-child::after {
    display: none
}

.landing .blog-info-link::after {
    content: "";
    display: block;
    clear: both;
    display: table
}

.landing .blog_item_img {
    position: relative
}

.landing .blog_item_img .blog_item_date {
    position: absolute;
    top: 15px;
    left: 15px;
    display: block;
    color: #fff;
    background-color: #4527a4;
    padding: 13px 30px;
    border-radius: 5px
}

.landing .blog_item_img .blog_item_date h3 {
    font-size: 22px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 0;
    line-height: 1.2
}

@media (min-width: 768px) {
    .landing .blog_item_img .blog_item_date h3 {
        font-size: 30px
    }
}

.landing .blog_item_img .blog_item_date p {
    font-size: 18px;
    margin-bottom: 0;
    color: #fff
}

@media (min-width: 768px) {
    .landing .blog_item_img .blog_item_date p {
        font-size: 18px
    }
}

.landing .blog_right_sidebar .widget_title {
    font-size: 17px;
    margin-bottom: 0;
    padding: 15px;
    border-bottom: 1px solid #e9e9e9;
}

.landing .media.post_item {
    border-bottom: 1px solid #e9e9e9;
    padding: 15px;
}

.landing .media.post_item:last-child {
    border-bottom: none;
}

.landing .blog_right_sidebar .single_sidebar_widget {
    margin-bottom: 30px;
}

.landing .blog_right_sidebar .single_sidebar_widget .btn_1 {
    margin-top: 0px;
    border: none;
    cursor: pointer;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.landing .btn.search-btn {
    background-color: #4527a4;
    height: 50px;
    position: relative;
    z-index: 1;
    right: 5px;
    border-radius: 0 5px 5px 0;
    color: #fff;
    font-size: 14px;
}

.landing .blog_right_sidebar .search_widget .form-control {
    height: 50px;
    border-color: #e9e9e9;
    font-size: 13px;
    color: #999999;
    padding-left: 20px;
    border-radius: 0;
    border-right: 0
}

.landing .blog_right_sidebar .search_widget .form-control::-webkit-input-placeholder {
    color: #999999
}

.landing .blog_right_sidebar .search_widget .form-control::-moz-placeholder {
    color: #999999
}

.landing .blog_right_sidebar .search_widget .form-control:-ms-input-placeholder {
    color: #999999
}

.landing .blog_right_sidebar .search_widget .form-control::-ms-input-placeholder {
    color: #999999
}

.landing .blog_right_sidebar .search_widget .form-control::placeholder {
    color: #999999
}

.landing .blog_right_sidebar .search_widget .input-group button {
    background: #fff;
    border-left: 0;
    border: 1px solid #e9e9e9;
    padding: 4px 15px;
    border-left: 0
}

.landing .blog_right_sidebar .search_widget .input-group button i,
.landing .blog_right_sidebar .search_widget .input-group button span {
    font-size: 14px;
    color: #999999
}

.landing .blog_right_sidebar .newsletter_widget .form-control {
    height: 50px;
    border-color: #e9e9e9;
    font-size: 13px;
    color: #999999;
    padding-left: 20px;
    border-radius: 0
}

.landing .blog_right_sidebar .newsletter_widget .form-control::-webkit-input-placeholder {
    color: #999999
}

.landing .blog_right_sidebar .newsletter_widget .form-control::-moz-placeholder {
    color: #999999
}

.landing .blog_right_sidebar .newsletter_widget .form-control:-ms-input-placeholder {
    color: #999999
}

.landing .blog_right_sidebar .newsletter_widget .form-control::-ms-input-placeholder {
    color: #999999
}

.landing .blog_right_sidebar .newsletter_widget .form-control::placeholder {
    color: #999999
}

.landing .blog_right_sidebar .newsletter_widget .form-control:focus {
    border-color: #e9e9e9;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

.landing .blog_right_sidebar .newsletter_widget .input-group button {
    background: #fff;
    border-left: 0;
    border: 1px solid #e9e9e9;
    padding: 4px 15px;
    border-left: 0
}

.landing .blog_right_sidebar .newsletter_widget .input-group button i,
.landing .blog_right_sidebar .newsletter_widget .input-group button span {
    font-size: 14px;
    color: #999999
}

.landing .blog_right_sidebar .post_category_widget .cat-list li {
    border-bottom: 1px solid #e9e9e9;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    padding: 10px 15px;
}

.landing .blog_right_sidebar .post_category_widget .cat-list li:last-child {
    border-bottom: 0
}

.landing .blog_right_sidebar .post_category_widget .cat-list li a {
    font-size: 14px;
    line-height: 20px;
    color: #888888
}

.landing .single_sidebar_widget.popular_post_widget {
    background-color: #fff;
}

.landing .blog_right_sidebar .post_category_widget .cat-list li:hover {
    background-color: #f7f7f7;
}

.landing .single_sidebar_widget.tag_cloud_widget {
    background-color: #fff;
}

.landing .single_sidebar_widget.post_category_widget {
    background-color: #fff;
}

.landing .blog_right_sidebar .post_category_widget .cat-list li a p {
    margin-bottom: 0px;
    color: #4b5264;
    font-size: 14px;
    -webkit-transition-duration: 300ms;
    -o-transition-duration: 300ms;
    transition-duration: 300ms;
}

.landing .blog_right_sidebar .post_category_widget .cat-list li a p:hover {
    color: #4527a4;
}

.landing .blog_right_sidebar .post_category_widget .cat-list li + li {
    padding-top: 15px
}

.landing .blog_right_sidebar .popular_post_widget .post_item .media-body {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    padding-left: 20px
}

.landing .blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
    font-size: 15px;
    margin-bottom: 5px;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.landing .blog_right_sidebar .popular_post_widget .post_item .media-body h3:hover {
    color: #4527a4;
}

.landing .blog_right_sidebar .popular_post_widget .post_item .media-body a:hover {
    color: #fff
}

.landing .list-tag {
    padding: 15px 15px 0 15px;
}

.landing .blog_right_sidebar .popular_post_widget .post_item .media-body p {
    font-size: 13px;
    margin-bottom: 0px
}

.landing .blog_right_sidebar .tag_cloud_widget ul li {
    display: inline-block
}

.landing .blog_right_sidebar .tag_cloud_widget ul li a {
    display: inline-block;
    border: 1px solid #e9e9e9;
    background: #fff;
    padding: 5px 25px;
    margin-bottom: 8px;
    margin-right: 3px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    color: #4b5264;
    font-size: 13px;
}

.landing .blog_right_sidebar .tag_cloud_widget ul li a:hover {
    background: #4527a4;
    color: #fff !important;
    -webkit-text-fill-color: #fff;
    text-decoration: none;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s
}

.landing .blog_right_sidebar .instagram_feeds .instagram_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: -6px;
    margin-left: -6px;
    padding: 15px 15px 0 15px;
}

.landing .blog_right_sidebar .instagram_feeds .instagram_row li {
    width: 33.33%;
    float: left;
    padding-right: 6px;
    padding-left: 6px;
    margin-bottom: 15px
}

.landing .blog_right_sidebar .br {
    width: 100%;
    height: 1px;
    background: #e9e9e9;
    margin: 30px 0px
}

.landing .blog-pagination .page-link {
    font-size: 14px;
    position: relative;
    display: block;
    padding: 0;
    text-align: center;
    margin-left: -1px;
    line-height: 45px;
    width: 45px;
    height: 45px;
    border-radius: 0 !important;
    color: #333;
    border: 1px solid #ebebeb;
    margin-right: 10px
}

.landing .blog-pagination .page-link i,
.landing .blog-pagination .page-link span {
    font-size: 13px
}

.landing .blog-pagination .page-item.active .page-link {
    background-color: #fbf9ff;
    border-color: #e9e9e9;
    color: #888888
}

.landing .page-link:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.landing .blog-pagination .page-item:last-child .page-link {
    margin-right: 0
}

.landing .single-post-area .blog_details {
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0
}

.landing .single-post-area .social-links {
    padding-top: 10px
}

.landing .single-post-area .social-links li {
    display: inline-block;
    margin-bottom: 10px
}

.landing .single-post-area .social-links li a {
    color: #cccccc;
    padding: 7px;
    font-size: 14px;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear
}

.landing .single-post-area .blog_details {
    padding-top: 26px
}

.landing .single-post-area .blog_details p {
    margin-bottom: 20px;
    font-size: 15px
}

.landing .single-post-area .quote-wrapper {
    background: #fff;
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
    padding: 15px;
    line-height: 1.733;
    color: #888888;
    font-style: italic;
    margin-top: 25px;
    margin-bottom: 25px
}

@media (min-width: 768px) {
    .landing .single-post-area .quote-wrapper {
        padding: 30px
    }
}

.landing .single-post-area .quotes {
    background: #fff;
    padding: 15px 15px 15px 20px;
    border-left: 2px solid#4527a4;
    font-size: 18px;
    font-weight: 500;
    color: #212529;
}

@media (min-width: 768px) {
    .landing .single-post-area .quotes {
        padding: 25px 25px 25px 30px
    }
}

.landing .single-post-area .arrow {
    position: absolute;
}

.landing .single-post-area .arrow .lnr {
    font-size: 20px;
    font-weight: 600
}

.landing .single-post-area .thumb .overlay-bg {
    background: rgba(0, 0, 0, 0.8)
}

.landing .single-post-area .navigation-top {
    padding-top: 15px;
    border-top: 1px solid #e9e9e9
}

.landing .single-post-area .navigation-top p {
    margin-bottom: 0
}

.landing .single-post-area .navigation-top .like-info {
    font-size: 14px
}

.landing .single-post-area .navigation-top .like-info i,
.landing .single-post-area .navigation-top .like-info span {
    font-size: 16px;
    margin-right: 5px
}

.landing .single-post-area .navigation-top .comment-count {
    font-size: 14px
}

.landing .single-post-area .navigation-top .comment-count i,
.landing .single-post-area .navigation-top .comment-count span {
    font-size: 16px;
    margin-right: 5px
}

.landing .single-post-area .navigation-top .social-icons li {
    display: inline-block;
    margin-right: 15px
}

.landing .single-post-area .navigation-top .social-icons li:last-child {
    margin: 0
}

.landing .single-post-area .navigation-top .social-icons li i,
.landing .single-post-area .navigation-top .social-icons li span {
    font-size: 15px;
    color: #4b5264;
    -webkit-transition-duration: 400ms;
    -o-transition-duration: 400ms;
    transition-duration: 400ms;
}

.landing .related-post-area h4 {
    font-size: 18px;
    margin-bottom: 30px;
}

.landing .single-post-area .navigation-top .social-icons li i:hover {
    color: #4527a4;
}

.landing .blog_details p {
    line-height: 1.8;
}

.landing .single-post-area .blog-author {
    padding: 40px 30px;
    background: #fbf9ff;
    margin-top: 50px;
    margin-bottom: 50px;
}

@media (max-width: 600px) {
    .landing .single-post-area .blog-author {
        padding: 20px 8px
    }
}

.landing .single-post-area .blog-author img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 30px
}

@media (max-width: 600px) {
    .landing .single-post-area .blog-author img {
        margin-right: 15px;
        width: 45px;
        height: 45px
    }
}

.landing .single-post-area .blog-author a {
    display: inline-block
}

.landing .single-post-area .blog-author a:hover {
    color: #2d1fb4
}

.landing .single-post-area .blog-author p {
    margin-bottom: 0;
    font-size: 15px
}

.landing .single-post-area .blog-author h4 {
    font-size: 16px
}

.landing .single-post-area .navigation-area {
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 30px;
    margin-top: 55px
}

.landing .single-post-area .navigation-area p {
    margin-bottom: 0px
}

.landing .single-post-area .navigation-area h4 {
    font-size: 15px;
    line-height: 25px
}

.landing .single-post-area .navigation-area .nav-left {
    text-align: left
}

.landing .single-post-area .navigation-area .nav-left .thumb {
    margin-right: 20px;
    background: #000
}

.landing .single-post-area .navigation-area .nav-left .thumb img {
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

.landing .single-post-area .navigation-area .nav-left .lnr {
    margin-left: 20px;
    opacity: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

.landing .single-post-area .navigation-area .nav-left:hover .lnr {
    opacity: 1
}

.landing .single-post-area .navigation-area .nav-left:hover .thumb img {
    opacity: .5
}

@media (max-width: 767px) {
    .landing .single-post-area .navigation-area .nav-left {
        margin-bottom: 30px
    }
}

.landing .single-post-area .navigation-area .nav-right {
    text-align: right
}

.landing .single-post-area .navigation-area .nav-right .thumb {
    margin-left: 20px;
    background: #000
}

.landing .single-post-area .navigation-area .nav-right .thumb img {
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

.landing .single-post-area .navigation-area .nav-right .lnr {
    margin-right: 20px;
    opacity: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

.landing .single-post-area .navigation-area .nav-right:hover .lnr {
    opacity: 1
}

.landing .single-post-area .navigation-area .nav-right:hover .thumb img {
    opacity: .5
}

@media (max-width: 991px) {
    .landing .single-post-area .sidebar-widgets {
        padding-bottom: 0px
    }
}

.landing .single-blog-area {
    position: relative;
    z-index: 1;
}

.landing .single-blog-area .blog-thumb {
    position: relative;
    z-index: 1;
}

.landing .single-blog-area .blog-thumb .date {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 22;
    background-color: #fe982f;
    color: #fff;
    height: 60px;
    width: 70px;
    text-align: center;
    line-height: 60px;
    display: inline-block;
    font-weight: 500;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    border-radius: 2px;
}

.landing .blog-content {
    background-color: #fff;
    padding: 20px;
    margin: 0 15px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .07);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .07);
    position: relative;
    z-index: 22;
    margin-top: -50px;
    -webkit-transition-duration: 400ms;
    -o-transition-duration: 400ms;
    transition-duration: 400ms;

}

.landing .blog-content a h3 {
    line-height: 35px;
    -webkit-transition-duration: 400ms;
    -o-transition-duration: 400ms;
    transition-duration: 400ms;
    margin-bottom: 0;
}

.landing .blog-content a h3:hover {
    color: #fe982f;
}

.landing .single-blog-area:hover .blog-content {
    margin-left: 0;
    margin-right: 0;
}

.landing .single-blog-area:hover .blog-thumb .date {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
}

.landing .blog-content .comment a {
    font-size: 12px;
    font-weight: 500;
    -webkit-transition-duration: 400ms;
    -o-transition-duration: 400ms;
    transition-duration: 400ms;
}

.landing .blog-content .comment a i {
    color: #555;
    -webkit-transition-duration: 400ms;
    -o-transition-duration: 400ms;
    transition-duration: 400ms;
}

.landing .blog-content .comment a:hover {
    color: #fe982f;
}

.landing .blog-content .comment a:hover i {
    color: #fe982f;
}

.landing .blog-content a {
    font-size: 14px;
    font-weight: 500;
    -webkit-transition-duration: 400ms;
    -o-transition-duration: 400ms;
    transition-duration: 400ms;
}

.landing .blog-content a:hover {
    color: #fe982f;
}

/* :: Comments Area CSS */

.landing .comments-area {
    background: transparent;
    border-top: 1px solid #e9e9e9;
    padding: 50px 0;
}

@media (max-width: 414px) {
    .landing .comments-area {
        padding: 50px 8px
    }
}

.landing .comments-area h4 {
    margin-bottom: 35px;
    font-size: 18px
}

.landing .comments-area h5 {
    font-size: 16px;
    margin-bottom: 5px
}

.landing .comments-area .comment-list {
    padding-bottom: 48px
}

.landing .comments-area .comment-list:last-child {
    padding-bottom: 0px
}

.landing .comments-area .comment-list.left-padding {
    padding-left: 25px
}

@media (max-width: 413px) {
    .landing .comments-area .comment-list .single-comment h5 {
        font-size: 12px
    }

    .landing .comments-area .comment-list .single-comment .date {
        font-size: 12px
    }

    .landing .comments-area .comment-list .single-comment .comment {
        font-size: 13px
    }
}

.landing .comments-area .thumb {
    margin-right: 20px
}

.landing .comments-area .thumb {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70px;
    flex: 0 0 70px;
    max-width: 70px;
    max-width: 70px;
}

.landing .comments-area .date {
    font-size: 13px;
    color: #4b5264;
    margin-bottom: 15px;
}

.landing .comments-area .comment {
    margin-bottom: 10px;
    color: #777777;
    font-size: 15px
}

.landing .comments-area .btn-reply {
    background-color: transparent;
    color: #333;
    padding: 5px 18px;
    font-size: 13px;
    display: block;
    font-weight: 500;
    border: 1px solid #ebebeb;
    -webkit-transition-duration: 400ms;
    -o-transition-duration: 400ms;
    transition-duration: 400ms;
}

.landing .comments-area .btn-reply:hover {
    background-color: #4527a4;
    color: #fff;
}

.landing .page-link:hover {
    color: #fff;
    background-color: #4527a4;
    border-color: #4527a4;
}

.landing .blog-pagination .page-item.active .page-link {
    background-color: #4527a4;
    border-color: #4527a4;
    color: #fff;
}

.landing .breadcrumb_iner_item h6 {
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);
}


.landing .modal-backdrop {
    background-color: transparent;
    z-index: auto;
}

/* :: Gallery Area CSS */
.landing .our-gallery-area .gallery-content img {
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .landing .our-gallery-area .portfolio-menu {
        text-align: center;
    }
}

.landing .our-gallery-area .portfolio-menu button {
    position: relative;
    z-index: 1;
    border: none;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    display: inline-block;
    background-color: transparent;
}

.landing .our-gallery-area .portfolio-menu button::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0;
    margin: auto;
    background-color: #4527a4;
    right: 0;
    -webkit-transition-duration: 400ms;
    -o-transition-duration: 400ms;
    transition-duration: 400ms;
}

@media only screen and (max-width: 767px) {
    .landing .our-gallery-area .portfolio-menu button::after {
        font-size: 12px;
    }
}

.landing .our-gallery-area .portfolio-menu button.active {
    color: #4527a4;
}

.landing .our-gallery-area .portfolio-menu button.active::after {
    width: 100%;
}

.landing .our-gallery-area .single_gallery_item {
    overflow: hidden;
    margin-bottom: 30px;
}

.landing .our-gallery-area .single_gallery_item .gallery-content {
    position: relative;
    z-index: 1;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    overflow: hidden;
}

.landing .our-gallery-area .single_gallery_item .gallery-content img {
    -webkit-transition-duration: 700ms;
    -o-transition-duration: 700ms;
    transition-duration: 700ms;
}

.landing .our-gallery-area .single_gallery_item .gallery-content .gallery-hover-overlay {
    position: absolute;
    content: "";
    background-color: rgba(0, 51, 204, 0.80);
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    z-index: 1;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    opacity: 0;
}

.landing .our-gallery-area .single_gallery_item .gallery-content .gallery-hover-overlay .port-more-view {
    position: absolute;
    z-index: 99;
    bottom: 30px;
    left: 30px;
}

.landing .our-gallery-area .single_gallery_item .gallery-content .gallery-hover-overlay .port-more-view .port-icon a {
    font-size: 18px;
    color: #ffffff;
    margin-right: 15px;
}

.landing .our-gallery-area .single_gallery_item:hover .gallery-content img {
    width: 100%;
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

.landing .our-gallery-area .single_gallery_item:hover .gallery-content .gallery-hover-overlay {
    opacity: 0.85;
}

.landing .our-gallery-area .port-text {
    margin-top: 20px;
}

.landing .our-gallery-area .port-text h4 a {
    font-size: 22px;
    color: #fff;
    font-weight: 500;
}

.landing .gallery-content-single {
    -webkit-box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.landing .gallery-content-single .gallery--thumb {
    position: relative;
    z-index: 1;
    -webkit-transition-duration: 1000ms;
    -o-transition-duration: 1000ms;
    transition-duration: 1000ms;
}

.landing .gallery-content-single .gallery--thumb img {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.landing .gallery-content-single .gallery--thumb::after {
    position: absolute;
    content: "";
    background-color: rgba(0, 51, 204, 0.80);
    height: 110%;
    width: 47%;
    right: -108px;
    bottom: -140px;
    z-index: 1;
    -webkit-transform: rotate(36deg);
    -ms-transform: rotate(36deg);
    transform: rotate(36deg);
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.landing .gallery-content-single .gallery-text {
    background-color: #fff;
    padding: 20px;
    border-radius: 0 0 5px 5px;
    position: relative;
    z-index: 9;
}

.landing .gallery-content-single .gallery-text h4 a {
    font-size: 18px;
    text-transform: uppercase;
}

.landing .gallery-content-single .gallery-text h4 a:hover {
    color: #4527a4;
}

.landing .gallery-content-single .gallery-text p {
    font-size: 13px;
}

.landing .gallery-content-single:hover .gallery--thumb img {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

.landing .gallery-content-single:hover .gallery--thumb::after {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
}

.landing .single-gallery-area h2 {
    font-size: 34px;
    margin-bottom: 20px;
}

.landing .single-gallery-area h4 {
    color: #4527a4;
    font-size: 16px;
    text-transform: capitalize;
}

.landing .single-gallery-area .product-share a {
    margin-right: 8px;
    font-size: 15px;
    -webkit-transition-duration: 400ms;
    -o-transition-duration: 400ms;
    transition-duration: 400ms;
}

.landing .single-gallery-area .product-share a:hover {
    color: #4527a4;
}

/* ---- 404 ---- */

.landing .onbita-error-page-area {
    min-height: 100vh;
    width: 100%;
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.landing .error-content {
    text-align: center;
    padding: 30px 15px;
}

.landing .error-content h2 {
    font-size: 44px;
    margin-top: 70px;
}

.landing .error-content p {
    font-size: 18px;
    max-width: 500px;
    margin: auto;
}

.landing .erro-thumb img {
    max-width: 100%;
    height: auto;
}

.landing .onita-btn- {
    height: 60px;
    width: 240px;
    line-height: 56px;
    text-align: center;
    text-decoration: none;
    border-radius: 30px;
    display: inline-block;
    background-color: transparent;
    border: 1px solid #fff;
    margin-top: 50px;
    color: #fff;
    font-weight: 500;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    text-transform: capitalize;
}

.landing .onbita-btn-:hover {
    background-color: #4527a4;
    border-color: #4527a4;
    color: #fff !important;
}

/* ---- Coming Soon ----- */

.landing .coming-soon-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    min-height: 100vh;
    position: relative;
    z-index: 2;
}

.landing .coming-soon-area .logo-area a img {
    max-width: 150px;
}

.landing .coming-soon-content {
    position: relative;
    z-index: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 50px 0;
    min-height: 100vh;
    width: 100%;
}

.landing .coming-soon-content .text-form-area {
    position: relative;
    z-index: 1;
}

.landing .coming-soon-content .text-form-area h2 {
    color: #fff;
    font-size: 48px;
    font-weight: 600;
    margin-top: 15px;
}

@media only screen and (max-width: 767px) {
    .landing .coming-soon-content .text-form-area h2 {
        font-size: 42px;
    }
}

.landing .coming-soon-content .text-form-area p {
    font-size: 18px;
    color: #fff;
}

.landing .coming-soon-content .text-form-area .news-letter-area .cs-form {
    position: relative;
    z-index: 1;
}

.landing .coming-soon-content .text-form-area .news-letter-area .cs-form .form-control {
    background-color: #ffffff;
    height: 60px;
    border: none;
    -webkit-box-shadow: 0px 24px 38px 0px rgba(62, 89, 96, 0.18);
    box-shadow: 0px 24px 38px 0px rgba(62, 89, 96, 0.18);
    border-radius: 5px;
    padding-left: 30px;
    padding-right: 30px;
}

.landing .coming-soon-content .text-form-area .news-letter-area .cs-form button {
    border: none;
    height: 60px;
    width: 200px;
    background-color: #4527a4;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    position: absolute;
    text-transform: uppercase;
    letter-spacing: 1px;
    top: 0;
    right: 0;
    z-index: 22;
    border-radius: 0 5px 5px 0;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    cursor: pointer;
}

@media only screen and (max-width: 767px) {
    .landing .coming-soon-content .text-form-area .news-letter-area .cs-form button {
        width: 100px;
        font-size: 14px;
    }
}

.landing .coming-soon-content .text-form-area .news-letter-area .cs-form button:hover {
    background-color: #22105f;
}

.landing .coming-soon-content .social-info-area p {
    display: block;
    margin-bottom: 10px;
    color: #ffffff;
    font-size: 18px;
}

.landing .coming-soon-content .social-info-area a {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    color: #ffffff;
    margin-right: 10px;
    display: inline-block;
    border-radius: 50%;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.landing .coming-soon-content .social-info-area a:hover {
    background-color: #4527a4;
}

.landing .coming-soon-content .countdown-timer {
    margin-top: 50px;
    border-radius: 3px;
    margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
    .landing .coming-soon-content .countdown-timer {
        padding: 15px;
        margin-top: 50px;
    }
}

.landing .coming-soon-content .countdown-timer #clock {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.landing .coming-soon-content .countdown-timer #clock div {
    position: relative;
    z-index: 1;
    font-size: 44px;
    line-height: 1;
    font-weight: 600;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    width: 25%;
    color: #fff;
    margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
    .landing .coming-soon-content .countdown-timer #clock div {
        font-size: 30px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .landing .coming-soon-content .countdown-timer #clock div {
        font-size: 50px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .landing .coming-soon-content .countdown-timer #clock div {
        font-size: 60px;
    }

    .landing .display--none {
        display: none;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .landing .coming-soon-content .countdown-timer #clock div {
        font-size: 60px;
    }
}

.landing .coming-soon-content .countdown-timer #clock div::after {
    content: ":";
    position: absolute;
    bottom: 20px;
    right: 0;
    font-size: 16px;
    color: #fff;
}

.landing .coming-soon-content .countdown-timer #clock div:last-child::after {
    display: none;
}

.landing .coming-soon-content .countdown-timer #clock div span {
    margin-top: 20px;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    display: block;
    text-transform: uppercase;
    letter-spacing: 2px;
}

@media only screen and (max-width: 767px) {
    .landing .coming-soon-content .countdown-timer #clock div span {
        font-size: 14px;
    }
}

.landing .h-100vh {
    height: 100vh !important;
}

.landing .font-13 {
    font-size: 13px;
}

.landing .font-14 {
    font-size: 14px;
}

.landing .font-18 {
    font-size: 18px;
}

.landing .middle-box {
    -webkit-box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .50);
    box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .50);
}

/* ---- Download area css ----- */

.landing .download-area h2 {
    font-size: 52px;
    color: #fff;
    margin-bottom: 20px;
}

.landing .download-area h6 {
    font-size: 18px;
    color: #fff;
    margin-bottom: 0;
    font-weight: 500;
}

/* ----- FAQ Area CSS ----- */

.landing #accordion a.card-header {
    color: #fff;
}

.landing #accordion a.card-header h6 {
    color: #fff;
}

.landing #accordion .card-header {
    padding: 20px;

    background-color: #4527a4;
    border-bottom: none;
    cursor: pointer;
    margin-bottom: 15px;
}

.landing #accordion .card-header.collapsed::after {
    content: "\e61a";
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.landing #accordion .card-header::after {
    font-family: 'themify';
    content: "\e622";
    float: right;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.landing .border-none {
    border: none;
}

.landing .mt-80-cu {
    margin-top: 80px;
}

/* ---- Responsive css ---- */

@media (min-width: 992px) and (max-width: 1199px) {
    .landing .download-content h4 {
        font-size: 42px;
    }

    .landing .welcome-content-3 h3 {
        font-size: 40px;
    }

    .landing .welcome-area {
        height: 700px;
    }

    .landing .hosting-feature-content-text h4 {
        font-size: 20px;
    }

    .landing .about-area-content-3 h2 {
        font-size: 40px;
    }

    .landing .we-offer-content-3 h3 {
        font-size: 40px;
    }

    .landing .single-hosting-text h4 {
        font-size: 18px;
    }

    .landing .hosting-feature-content-text h4 {
        font-size: 18px;
    }

    .landing .single-item-text-3 h4 {
        font-size: 20px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .landing .header-area .main-header-area {
        padding: 20px;
    }

    .landing .slicknav_btn {
        margin-bottom: 0;
        padding: 0;
        right: 0;
        top: -28px;
    }

    .landing .slicknav_nav {
        top: 20px;
    }

    .landing .welcome-area.home-2 {
        height: 700px;
    }

    .landing .welcome-content h3 {
        font-size: 40px;
    }

    .landing .hosting-feature-content-text h4 {
        font-size: 20px;
    }

    .landing .download-content h4 {
        font-size: 34px;
        line-height: 1.6;
    }

    .landing .manage-content h3 {
        font-size: 30px;
    }

    .landing .single-hosting-text h4 {
        font-size: 18px;
    }

    .landing .about-area-content-hosting h2 {
        font-size: 30px;
    }

    .landing .welcome-content h5 {
        font-size: 16px;
    }

    .landing .welcome-content.two h3 {
        font-size: 38px;
    }

    .landing .welcome-area {
        height: 700px;
    }

    .landing .about-area-content h2 {
        font-size: 28px;
    }

    .landing .we-offer-content h3 {
        font-size: 28px;
    }

    .landing .we-offer-content span {
        font-size: 16px;
    }

    .landing .about-area-content p {
        font-size: 15px;
    }


    .landing .download-content p {
        font-size: 16px;
    }

    .landing .video-content p {
        width: 52%;
    }

    .landing .welcome-content-4 h3 {
        font-size: 38px;
    }

    .landing .about-content-4 h2 {
        font-size: 32px;
    }

    .landing .navbar-collapse .navbar-nav.mx-auto {
        background-color: #fff;
    }

    .landing .custom_nav_menu .navbar-nav li a {
        color: #111;
    }

    .landing .stickyadd.custom_nav_menu .navbar-nav li a {
        color: #111;
    }

    .landing .stickyadd.custom_nav_menu {
        padding: 8px 10px;
    }

    .landing .custom_nav_menu {
        padding: 15px 10px;
    }

    .landing .custom_nav_menu.home-3.sticky .navbar-toggler {
        color: #4527a4;
    }

    .landing .welcome-area.home-3.single {
        height: 900px;
    }

    .landing .custom_nav_menu.home-3.sticky {
        border-bottom: 1px solid rgba(45, 31, 180, 0.20);
    }

    .landing .badge.custom {
        top: 8px;
    }

    .landing .about-thumb {
        padding-right: 0;
    }

    .landing .video-content-area {
        padding-right: 0;
    }

    .landing .video-content-text h2 {
        font-size: 28px;
    }

    .landing .video-content-text p {
        font-size: 15px;
    }

    .landing .single-hero-slide h2 {
        font-size: 38px;
    }

    .landing .welcome-area.home-3 {
        height: 700px;
    }

    .landing .about-shape-3 {
        width: 350px;
    }

    .landing .about-area-content-3 h2 {
        font-size: 36px;
    }

    .landing .we-offer-content-3 h3 {
        font-size: 36px;
    }

    .landing .we-offer-thumb-3 {
        padding-left: 0;
    }

    .landing .about-area-content-3 p {
        font-size: 16px;
    }

    .landing .about-area-content-3 {
        padding-left: 0;
    }

    .landing .why-area-content h2 {
        font-size: 28px;
    }

    .landing .partner-content-text h2 {
        font-size: 36px;
    }

    .landing .blog_right_sidebar {
        padding-bottom: 50px;
    }

    .landing .hosting-search-area {
        margin-top: -140px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .landing .header-area .main-header-area {
        padding: 20px 2px;
    }

    .landing .slicknav_btn {
        margin-bottom: 0;
        padding: 0;
        right: 0;
    }

    .landing .slicknav_nav {
        top: 20px;
    }

    .landing .single-item-text-3 h4 {
        font-size: 18px;
    }

    .landing .breadcrumb.breadcrumb_bg {
        height: 250px;
    }

    .landing .welcome-content h3 {
        font-size: 30px;
    }

    .landing .logo a img {
        width: 70px;
    }

    .landing .welcome-area {
        height: 900px;
    }

    .landing .single-hosting-text h4 {
        font-size: 18px;
    }

    .landing .welcome-content h5 {
        font-size: 14px;
    }

    .landing .mb-sm-20 {
        margin-bottom: 20px;
    }

    .landing .mb-sm-30 {
        margin-bottom: 30px;
    }

    .landing .section-title h2 {
        font-size: 28px;
    }

    .landing .download-content h4 {
        font-size: 30px;
    }

    .landing .about-content-4 h2 {
        font-size: 28px;
    }

    .landing .why-area-content h2 {
        font-size: 28px;
    }

    .landing .breadcrumb_iner_item h2 {
        font-size: 26px;
    }

    .landing .clint-thumb-text {
        margin-right: 20px;
    }

    .landing .download-content p {
        font-size: 16px;
    }

    .landing .section-title p {
        font-size: 14px;
    }

    .landing .about-area-content h2 {
        font-size: 28px;
    }

    .landing .we-offer-content h3 {
        font-size: 26px;
    }

    .landing .video-content h4 {
        font-size: 22px;
        line-height: 1.5;
    }

    .landing .video-content p {
        width: 100%;
    }

    .landing .mt-80-cu {
        margin-top: 0;
    }


    .landing .single-partner {
        margin-bottom: 15px;
    }

    .landing .error-content h2 {
        font-size: 30px;
    }

    .landing .navbar-collapse .navbar-nav.mx-auto {
        background-color: #fff;
    }

    .landing .custom_nav_menu .navbar-nav li a {
        color: #111;
    }

    .landing .stickyadd.custom_nav_menu .navbar-nav li a {
        color: #111;
    }

    .landing .stickyadd.custom_nav_menu {
        padding: 8px 10px;
    }

    .landing .custom_nav_menu {
        padding: 15px 10px;
    }

    .landing .custom_nav_menu.home-3.sticky {
        border-bottom: 1px solid rgba(45, 31, 180, 0.20);
    }

    .landing .custom_nav_menu.home-3.sticky .navbar-toggler {
        color: #4527a4;
    }

    .landing .single-partner {
        display: block;
        width: 150px;
        margin: 20px auto;
    }

    .landing .welcome-content p {
        font-size: 16px;
    }

    .landing .content-box .inner-box .single-item h5 a {
        margin-top: 15px;
    }

    .landing .video-content-text h2 {
        font-size: 28px;
    }

    .landing .copy-right-content p {
        margin-bottom: 20px;
    }

    .landing .copy-right-content {
        text-align: center;
    }

    .landing .about-thumb {
        padding-right: 0;
    }

    .landing .video-content-area {
        padding-right: 0;
    }

    .landing .copy-right-menu {
        text-align: center !important;
    }

    .landing .about-area-content h2 {
        font-size: 26px;
    }

    .landing .welcome-content.two h3 {
        font-size: 32px;
    }

    .landing .hosting-search-area .search-form .form-group button {
        position: relative;
        width: 100%;
        margin-top: 20px;
        border-radius: 5px;
    }

    .landing .hosting-search-area .domain-name li {
        width: 100%;
        margin-bottom: 15px;
    }

    .landing .about-area-content-hosting h2 {
        font-size: 26px;
    }

    .landing .hosting-feature-content-text h4 {
        font-size: 20px;
    }

    .landing .manage-content h3 {
        font-size: 30px;
    }

    .landing .welcome-area.home-2 {
        height: 750px;
    }

    .landing .single-hero-slide h2 {
        font-size: 30px;
    }

    .landing .single-hero-slide p {
        font-size: 16px;
    }

    .landing .welcome-area.home-3 {
        height: 1100px;
    }

    .landing .hero-image-3 {
        padding-left: 0;
    }

    .landing .about-area-content-3 {
        padding-left: 0;
    }

    .landing .about-area-content-3 h2 {
        font-size: 32px;
    }

    .landing .about-shape-3 {
        width: 300px;
    }

    .landing .we-offer-content-3 h3 {
        font-size: 32px;
    }

    .landing .partner-content-text h2 {
        font-size: 26px;
    }

    .landing .blog_right_sidebar {
        padding-bottom: 50px;
    }

    .landing .video-content-3 {
        margin-bottom: 50px;
    }
}

@media (min-width: 480px) and (max-width: 575px) {
    .landing .welcome-content h3 {
        font-size: 36px;
    }

    .landing .welcome-area {
        height: 1050px;
    }

    .landing .welcome-area.home-2 {
        height: 800px;
    }

    .landing .download-content h4 {
        font-size: 30px;
        line-height: 1.5;
    }

    .landing .welcome-content.two h3 {
        font-size: 40px;
    }

    .landing .about-area-content-hosting h2 {
        font-size: 30px;
    }

    .landing .video-content-text h2 {
        font-size: 36px;
    }

    .landing .we-offer-content h3 {
        font-size: 36px;
    }

    .landing .about-area-content h2 {
        font-size: 36px;
    }

    .landing .welcome-area.home-3 {
        height: 1200px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .landing .welcome-content h3 {
        font-size: 30px;
    }

    .landing .video-content-3 {
        margin-bottom: 50px;
    }

    .landing .welcome-area {
        height: 700px;
    }

    .landing .welcome-area.home-2 {
        height: 900px;
    }

    .landing .about-area-content h2 {
        font-size: 36px;
    }

    .landing .we-offer-content h3 {
        font-size: 36px;
    }

    .landing .video-content-text h2 {
        font-size: 36px;
    }

    .landing .section-title h2 {
        font-size: 28px;
    }

    .landing .we-offer-content h3 {
        font-size: 26px;
    }

    .landing .video-content h4 {
        font-size: 26px;
        line-height: 1.6;
    }

    .landing .download-content h4 {
        font-size: 40px;
    }

    .landing .we-offer-content h3 {
        font-size: 36px;
    }

    .landing .hosting-search-area .domain-name li {
        width: 20%;
        margin-bottom: 0;
    }

    .landing .hosting-search-area .domain-name li a span {
        font-size: 16px;
    }

    .landing .about-area-content-hosting h2 {
        font-size: 36px;
    }

    .landing .manage-content h3 {
        font-size: 36px;
    }

    .landing .welcome-area.home-3 {
        height: 700px;
    }

    .landing .single-hero-slide h2 {
        font-size: 28px;
    }

    .landing .why-area-content h2 {
        font-size: 36px;
    }
}
.landing .pt-6 {
    padding-top:6em;
}
.landing .fa {
    color:#22105f;
}

.landing .bg-landing {
    position: absolute;
    background-image:url('../../imgs/landing/bgvanilli.jpg');
    opacity: 0.04;
    width: 100%;
    height: 100%;
}

.landing select.form-control:not([size]):not([multiple]) {
    height:2.5rem;
}